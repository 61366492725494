import { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Link, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { RegistrationInput, useRegistration } from '../../api/auth';
import { LocalStorageKeys } from '../../hooks/useLocalStorage';
import { useAuth } from '../../providers/auth';

const validationSchema = yup.object({
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  agreement: yup.boolean().required(),
});

const initialValues: RegistrationInput = {
  firstName: '',
  email: '',
  agreement: false,
  inviterId: localStorage.getItem(LocalStorageKeys.inviterId),
};

export function RegistrationForm() {
  const auth = useAuth();
  const register = useRegistration();
  const { t } = useTranslation('registrationForm');

  useEffect(() => {
    if (register.data?.accessToken) {
      auth.setToken(register.data.accessToken);
    }
  }, [register]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) =>
        register.mutateAsync({
          ...values,
          inviterId: localStorage.getItem(LocalStorageKeys.inviterId),
        })
      }
    >
      {({ values }) => (
        <Form noValidate>
          <Typography variant="h4" align="center">
            Registration
          </Typography>
          <Field
            component={TextField}
            type="text"
            name="firstName"
            label={t('firstName')}
            autoComplete="given-name"
            margin="normal"
            autoFocus
            fullWidth
          />
          <Field
            component={TextField}
            type="email"
            name="email"
            label={t('email')}
            margin="normal"
            autoComplete="email"
            helperText={register.error?.message}
            error={register.isError}
            fullWidth
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="agreement"
            Label={{
              label: (
                <Typography variant="body1" component="span">
                  {t('checkAgreement')}
                  <Typography component="a" href="">
                    {t('userAgreement')}
                  </Typography>
                </Typography>
              ),
            }}
          />

          <LoadingButton
            loading={register.isLoading || register.isSuccess}
            type="submit"
            variant="contained"
            disabled={!values.agreement}
            fullWidth
          >
            {t('register')}
          </LoadingButton>
          <Typography margin={1} textAlign="center">
            {t('or')}
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Link href="/auth/login">{t('login')}</Link>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
