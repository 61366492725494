import React from 'react';

export function Logo() {
  return (
    <svg width="120" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M38.7 2.11c-1.655 1.656-2.207 4.449-2.792 7.565-.454 2.37-.974 5-1.915 5.942-.844.844-1.267 1.006-7.11.91v3.083c6.266.066 7.564.066 9.415-1.785 1.656-1.656 2.208-4.448 2.792-7.565.455-2.37.974-5 1.916-5.942.617-.617 1.46-.974 7.11-.909V.325C42.077.26 40.324.455 38.7 2.11Z"
          fill="#69E7F0"
        />
        <path
          d="M34.058 3.41h-7.207V.324h6.136l1.071 3.084ZM67.63 8.896h-12.5c.031-3.182.129-3.831.908-4.578.877-.876 2.403-1.006 8.085-1.006 2.305 0 4.902.065 6.233.065V.325C68.993.292 66.33.227 64.123.227c-6.364 0-8.54 0-10.39 1.851-1.85 1.85-1.85 3.539-1.85 8.02v9.48h3.246v-7.597h12.5V8.896ZM95.585 1.948C93.734.065 91.623.065 85.292.065c-6.33 0-8.441 0-10.292 1.883-1.85 1.85-1.85 3.54-1.85 8.02 0 4.48 0 6.168 1.85 8.02 1.85 1.882 3.961 1.882 10.292 1.882 6.331 0 8.442 0 10.293-1.883 1.85-1.85 1.85-3.539 1.85-8.02 0-4.48 0-6.168-1.85-8.019ZM93.279 15.78c-.844.877-2.37.974-7.987.974s-7.11-.13-7.987-.974c-.844-.876-.909-1.526-.909-5.811 0-4.286.065-4.968.91-5.812.843-.877 2.37-.974 7.986-.974 5.617 0 7.11.13 7.987.974.844.877.91 1.526.91 5.812 0 4.285-.066 4.967-.91 5.811ZM120 2.597l-2.305 2.306c-1.201-1.656-3.734-1.819-6.007-1.819-2.987 0-5.324.163-6.168.974-.812.812-1.234 1.494-1.234 5.812v9.74h-3.247V9.87c0-4.578.455-6.299 2.175-8.02C104.968.13 107.695 0 111.688 0c2.468 0 6.104 0 8.312 2.597ZM23.182 9.027c0-2.63 0-5.293-1.786-7.078C19.676.228 17.468.098 11.591.098S3.474.26 1.786 1.948C0 3.735 0 5.813 0 9.969s0 6.234 1.786 8.02c1.72 1.72 3.928 1.85 9.805 1.85 5.844 0 8.766 0 11.104-2.208l-2.305-2.305c-1.3 1.201-3.182 1.429-8.8 1.429-5.324 0-6.785-.26-7.532-1.007-.649-.617-.779-1.396-.811-4.578h19.87c.065-.649.065-1.266.065-2.142ZM3.247 8.085c.032-2.63.227-3.344.811-3.929.747-.714 2.208-.974 7.533-.974s6.786.26 7.532.974c.585.585.78 1.3.812 3.929H3.247ZM40.877 16.526h7.207v3.084H41.98l-1.103-3.084Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h120v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
