import { ComponentProps } from 'react';

import Typography from '@mui/material/Typography';

function mapMoneySign(currency: string) {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return currency;
  }
}

export function Money({
  value,
  currency,
  ...props
}: { value: string | number; currency: string } & ComponentProps<
  typeof Typography
>) {
  return (
    <Typography variant="body1" component="span" {...props}>
      {`${value} ${mapMoneySign(currency)}`}
    </Typography>
  );
}
