import { Box, Button, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { usePammPublicManagers } from '../../api/accounts';
import { DataGridStyled } from '../../components/Grid/DataGrid';
import { CustomPagination } from '../../components/Grid/Pagination';

export function Managers() {
  const { data, isLoading } = usePammPublicManagers();
  const { t } = useTranslation('pammDashboard');
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('manager'),
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'currency',
      headerName: t('currency'),
      type: 'string',
      minWidth: 50,
    },
    {
      field: 'description',
      headerName: t('description'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'investmentCount',
      headerName: t('investmentCount'),
      minWidth: 50,
      type: 'number',
    },
    {
      field: 'fundsManager',
      headerName: t('fundsManager'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'fundsTotal',
      headerName: t('fundsTotal'),
      minWidth: 50,
      type: 'number',
    },
    {
      field: 'profitTotal',
      headerName: t('profitTotal'),
      minWidth: 50,
      type: 'number',
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          window.location.href = `/pamm/manager/${params.row.id}`;
        };

        return (
          <Button variant="contained" color="secondary" onClick={onClick}>
            {t('view')}
          </Button>
        );
      },
    },
  ];

  const rows = data ? data : [];
  return (
    <Box>
      <Typography variant={'h6'} sx={{ mt: 1 }}>
        {t('publicPamm')}
      </Typography>
      <DataGridStyled
        rowHeight={43}
        columnHeaderHeight={39}
        rows={rows}
        loading={isLoading}
        columns={columns}
        slots={{
          pagination: CustomPagination,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableColumnSelector
        autoHeight
      />
    </Box>
  );
}
