export const symbolGroups = {
  forex: [
    'AUDUSD',
    'EURUSD',
    'GBPUSD',
    'NZDUSD',
    'USDCAD',
    'USDCHF',
    'USDJPY',
    'AUDCAD',
    'AUDCHF',
    'AUDJPY',
    'AUDNZD',
    'CADCHF',
    'CADJPY',
    'CHFDKK',
    'CHFJPY',
    'CHFNOK',
    'CHFPLN',
    'EURAUD',
    'EURCAD',
    'EURCHF',
    'EURGBP',
    'EURJPY',
    'EURMXN',
    'EURNOK',
    'EURNZD',
    'EURPLN',
    'EURSEK',
    'EURTRY',
    'EURHUF',
    'GBPAUD',
    'GBPCAD',
    'GBPCHF',
    'GBPDKK',
    'GBPJPY',
    'GBPNOK',
    'GBPNZD',
    'GBPPLN',
    'GBPSEK',
    'HKDJPY',
    'NOKJPY',
    'NOKSEK',
    'NZDCAD',
    'NZDCHF',
    'NZDSGD',
    'NZDJPY',
    'SEKJPY',
    'SGDJPY',
    'TRYJPY',
    'USDHKD',
    'USDNOK',
    'USDPLN',
    'ZARJPY',
    'AUDNOK',
    'AUDSEK',
    'AUDSGD',
    'CHFSGD',
    'EURHKD',
    'EURSGD',
    'GBPSGD',
    'GBPZAR',
    'MXNJPY',
    'USDCZK',
    'USDCNH',
    'USDDKK',
    'USDHUF',
    'USDMXN',
    'USDSEK',
    'USDSGD',
    'USDTRY',
    'USDZAR',
  ],
  metals: ['XAUUSD', 'XAUEUR', 'XAGUSD', 'XAGEUR', 'XPTUSD', 'XPDUSD'],
  commodities: ['WTI_OIL', 'UKOIL.c'],
  cfdIndices: [
    'AUS200.c',
    'DE40.c',
    'F40.c',
    'HK50.c',
    'NE25.c',
    'STOXX50.c',
    'UK100.c',
    'USTEC.c',
    'US500.c',
    'JP225.c',
    'DJ30.c',
    'ES35.c',
    'SWI20.c',
  ],
  cfdShares: [
    'AAPL.OQ',
    'AMZN.OQ',
    'AMD.OQ',
    'AMGN.OQ',
    'AMAT.OQ',
    'AXP.N',
    'AIG.N',
    'AAL.OQ',
    'ABNB.OQ',
    'ADBE.OQ',
    'AKAM.OQ',
    'ALL.N',
    'ATHM.N',
    'ATVI.OQ',
    'BAC.N',
    'BA.N',
    'BABA.N',
    'BBY.N',
    'BK.N',
    'BEN.N',
    'BIDU.OQ',
    'BKNG.OQ',
    'BIIB.OQ',
    'BRKb.N',
    'BLK.N',
    'BYND.OQ',
    'CVX.N',
    'CGC.N',
    'CSCO.OQ',
    'CRON.OQ',
    'CVS.N',
    'CMCSA.OQ',
    'CNC.N',
    'COST.OQ',
    'CRM.N',
    'DAL.N',
    'DBX.OQ',
    'DFS.N',
    'DIS.N',
    'DLTR.OQ',
    'DOCU.OQ',
    'DVMT.N',
    'EBAY.OQ',
    'EA.O',
    'EXPE.OQ',
    'FB.OQ',
    'F.N',
    'FOX.OQ',
    'FDX.N',
    'GE.N',
    'GM.N',
    'GS.N',
    'GOOG.OQ',
    'GDDY.N',
    'HPQ.N',
    'GILD.OQ',
    'HLT.N',
    'NFLX.OQ',
    'TWTR.N',
    'C.N',
    'IBM.N',
    'ILMN.OQ',
    'INTC.OQ',
    'JNJ.N',
    'JPM.N',
    'MSFT.OQ',
    'MU.OQ',
    'MA.N',
    'MCD.N',
    'ORCL.N',
    'PFE.N',
    'PG.N',
    'KO.N',
    'QCOM.OQ',
    'RACE.N',
    'T.N',
    'TEVA.P',
    'TSLA.OQ',
    'UBER.N',
    'V.N',
    'NIO.N',
    'XOM.N',
    'JD.OQ',
    'K.N',
    'KHC.OQ',
    'KKR.N',
    'L.N',
    'LI.OQ',
    'LMT.N',
    'LUV.N',
    'LVS.N',
    'LYFT.OQ',
    'MAN.N',
    'MAR.OQ',
    'MCO.N',
    'MET.N',
    'MGM.N',
    'MMM.N',
    'MO.N',
    'MOMO.OQ',
    'MRK.N',
    'MRNA.OQ',
    'MSI.N',
    'MTCH.OQ',
    'NKE.N',
    'NKLA.OQ',
    'NRG.N',
    'NTES.OQ',
    'NVDA.OQ',
    'OMC.N',
    'PDD.OQ',
    'PINS.N',
    'PLTR.N',
    'PLUG.OQ',
    'PM.N',
    'PAYX.OQ',
    'PNC.N',
    'PTON.OQ',
    'PYPL.OQ',
    'RTN.N',
    'ROST.OQ',
    'SBUX.OQ',
    'SCHW.N',
    'SHOP.N',
    'SPCE.N',
    'SQ.N',
    'TGT.N',
    'TLRY.OQ',
    'TME.N',
    'TMUS.N',
    'TQQQ.OQ',
    'TRIP.OQ',
    'TSM.N',
    'TSN.N',
    'TWLO.N',
    'TXN.O',
    'UAL.N',
    'VIPS.N',
    'VZ.N',
    'VRSN.OQ',
    'W.N',
    'WB.N',
    'WBA.N',
    'WDAY.N',
    'WIX.OQ',
    'WMT.N',
    'WYNN.OQ',
    'XPEV.N',
    'YELP.N',
    'YUM.N',
    'ZEN.N',
    'ZM.OQ',
    'CAJ.N',
    'PEP.N',
    'SONY.N',
    '1COV.DE',
    'ADSGn.DE',
    'AIR.PA',
    'AXAF.PA',
    'AIRF.PA',
    'ALVG.DE',
    'BNPP.PA',
    'BEIG.DE',
    'BASFn.DE',
    'BMWG.DE',
    'BAYGn.DE',
    'CBKG.DE',
    'CONG.DE',
    'CAPP.PA',
    'CARR.PA',
    'DAIGn.DE',
    'DANO.PA',
    'DPWGn.DE',
    'DBKGn.DE',
    'ENR1n.DE',
    'EONGn.DE',
    'FREG.DE',
    'HEIN.AS',
    'HRMS.PA',
    'HEIG.DE',
    'HNKG_p.DE',
    'IBE.MC',
    'IFXGn.DE',
    'LHAG.DE',
    'LVMH.PA',
    'LINI.DE',
    'MAP.MC',
    'MUVGn.DE',
    'MTXGn.DE',
    'OREP.PA',
    'PSHG_p.DE',
    'PUMG.DE',
    'RRTL.DE',
    'RWEG.DE',
    'SAPG.DE',
    'SAN.MC',
    'SOGN.PA',
    'SIEGn.DE',
    'TEF.MC',
    'TOTF.PA',
    'VNAn.DE',
    'VOWG_p.DE',
    'VAR1.DE',
    'ZALG.DE',
  ],
};
