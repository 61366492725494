import { useEffect } from 'react';

import { Grid, Paper, AppBar, Toolbar, Link } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import { useProfile } from '../../api/auth';
import { Logo } from '../../components/Logo/Logo';
import { BaseLayout } from '../../layouts/Base/BaseLayout';
import { useAuth } from '../../providers/auth';

export function Auth() {
  const navigate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();

  useEffect(() => {
    if (auth.authState === 'AUTHORIZED') {
      if (profile?.data?.emailVerified) {
        navigate(profile.data.verified ? '/' : '/profile/verification');
      } else {
        profile.refetch();
      }
    }
  }, [auth.authState, profile?.data?.emailVerified]);

  return (
    <BaseLayout isLoading={profile?.data?.emailVerified}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Grid container justifyContent="center" wrap="wrap">
            <Grid item>
              <Link href="/">
                <Logo />
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        justifyItems="center"
        alignItems="center"
        alignContent="center"
        direction="row"
      >
        <Paper
          component={Grid}
          item
          sx={{
            marginTop: 4,
            padding: 2,
            width: 600,
            sm: { padding: 4 },
            xs: { width: '100%' },
          }}
          variant="outlined"
        >
          <Outlet />
        </Paper>
      </Grid>
    </BaseLayout>
  );
}
