import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAxios } from '../providers/axios';
import {
  PammAccountData,
  PammInvestmentAccountData,
} from '../views/Pamm/MoneyManagerAccount/CreateMoneyManagerAccount';

import { Currency } from './general';

export type AccountType = 'live' | 'demo' | 'unknown';

export type Account = {
  id: string;
  name: string;
  type: AccountType;
  login: string;
  serverId: string;
  leverage: number;
  balance: string;
  currency: Currency;
  credit: string;
  equity: string;
  margin: string;
};

export type Subscription = {
  id: number;
  name: string;
  period: number;
  price: number;
};

export type UserServiceSubscription = {
  id: number;
  userId: number;
  subscriptionId: number;
  subscription: Subscription;
  nextSubscriptionId: number;
  nextSubscription: Subscription;
  create: Date | null;
  expire: Date | null;
  notice: Date[];
  isNotice: number;
  extended: boolean;
};

export interface AccountDefinition {
  id?: string;
  name?: string;
  login?: string;
  serverId?: number;
  userId?: number;
  /** @format date-time */
  createdAt?: string;
  groupName?: string;
  currency?: string;
  isEnabled?: number;
  leverage?: number;
  /** @format float */
  balance?: number;
  /** @format float */
  credit?: number;
  /** @format float */
  equity?: number;
  /** @format float */
  margin?: number;
  /**
   * Client's custom fields
   * @example {"custom_field_1":"Hello!","custom_field_2":"Bonjour!"}
   */
  customFields?: Record<string, string>;
  managerId?: number;
  accountTypeId?: number;
  tradingStatus?: string;
  companyId?: number;
  tags?: string[];
}

export interface AccountDefinitionMaped
  extends Pick<
    AccountDefinition,
    | 'login'
    | 'serverId'
    | 'leverage'
    | 'balance'
    | 'credit'
    | 'equity'
    | 'margin'
  > {
  full: AccountDefinition;
  id: string;
  type: string;
  name: string;
  currency: string;
}

export interface TransactionDefinition {
  id?: number;
  managerId?: number;
  /** @format date-time */
  createdAt?: string;
  type?: string;
  fromUserId?: number;
  /** @format float */
  requestedAmount?: number;
  requestedCurrency?: string;
  /** @format float */
  processedAmount?: number;
  processedCurrency?: string;
  /** @example "approved" */
  status?: string;
  /** System psp alias */
  psp?: string;
  pspId?: number;
  pspRuleId?: number;
  /** @example "2-154439" */
  fromLoginSid?: string;
  /** @format float */
  brokerCommission?: number;
  /** @format float */
  pspCommission?: number;
  /** @format date-time */
  processedAt?: string;
  /** @format float */
  source?: number;
  showToClient?: boolean;
  comment?: string;
  ticket?: string;
  platformComment?: string;
  /**
   * Custom fields
   * @example {"custom_field_1":"Hello!","custom_field_2":"Bonjour!"}
   */
  customFields?: Record<string, string>;
}

export type AccountStatusType = {
  isVerified: boolean;
  hasSubscription: UserServiceSubscription | null;
  hasDemo: AccountDefinition | null;
  hasLive: AccountDefinition | null;
  hasPayment: TransactionDefinition | null;
};

export interface IbTreeDefinition {
  ibId: string;
  level: string;
  referralIbId: string;
}

export enum PerformanceFeeMode {
  Equity = 'Equity',
  Return = 'Return ',
}

export enum PaymentFeeMode {
  Percentage = 'Percentage',
  Money = 'Money',
}

export enum TradingIntervalType {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  CalendarMonths = 'CalendarMonths',
}

export interface FeeLevel {
  level: number;
  value: number;
}

export interface ManagementFeeLevel {
  level: number;
  value: number;
  mode: PaymentFeeMode;
}

export interface PerformanceFeeSettings {
  levels: FeeLevel[];
  mode: PerformanceFeeMode;
}

export interface TradingInterval {
  type: TradingIntervalType;
  count: number;
}

export interface FeeLevelCollectionOfFeeLevel {
  levels: FeeLevel[];
}

export interface FeeLevelCollectionOfManagementFeeLevel {
  levels: ManagementFeeLevel[];
}

export interface RateOverrideSettings {
  ratePerformanceFees?: number;
  rateManagementFees?: number;
  rateWithdrawalFees?: number;
  rateDepositFees?: number;
  rateEntryFees?: number;
}

export interface AgentCommissionSettings {
  agentCommissionRate: number;
  agentCommissionDistribution: string;
  agentCommissionRateOverride?: RateOverrideSettings;
}

export interface ManagerOfferJoinLink {
  key?: string;
  expiration?: Date;
  oneTime: boolean;
}

export interface EntryFeeSettings {
  amount?: number;
  mode: PaymentFeeMode;
}

export interface OfferSettings {
  tradingInterval?: TradingInterval;
  minDeposit: number;
  minWithdrawal: number;
  minInitialInvestment: number;
  performanceFees?: PerformanceFeeSettings;
  earlyWithdrawalFees?: FeeLevelCollectionOfFeeLevel;
  depositFees?: FeeLevelCollectionOfFeeLevel;
  managementFees?: FeeLevelCollectionOfManagementFeeLevel;
  agentCommissions?: AgentCommissionSettings;
  agentChain?: string;
  joinLinks?: ManagerOfferJoinLink[];
  entryFees?: EntryFeeSettings;
  hasEntryFees: boolean;
}

export interface ManagerOfferDto {
  id: number;
  name?: string;
  description?: string;
  managerId: number;
  managerConfiguration?: number;
  currency?: string;
  activeInvestmentCount: number;
  isActive: boolean;
  settings?: OfferSettings;
}

export interface ManagerPublicDto {
  id: number;
  name?: string;
  currency?: string;
  description?: string;
  investmentCount?: number;
  fundsManager?: number;
  fundsTotal?: number;
  profitTotal?: number;
  offers: ManagerOfferDto[];
}

export interface UpdateOfferDto {
  name: string;
  description: string;
  isActive: boolean;
  minDeposit: number;
  minInitialInvestment: number;
  minWithdrawal: number;
  tradingIntervalCount: number;
  tradingIntervalType: TradingIntervalType;
}

export interface CreateOfferDto extends UpdateOfferDto {
  managerId: number;
}

export enum ManagerStatus {
  Pending = 'Pending',
  Suspended = 'Suspended',
  Closed = 'Closed',
}

export enum InvestmentStatus {
  Pending = 'Pending',
  Active = 'Active',
  Suspended = 'Suspended',
  Closed = 'Closed',
}

export enum ArchiveReason {
  Disabled = 'Disabled',
  AbsoluteLoss = 'AbsoluteLoss',
  RelativeLossDepositNet = 'RelativeLossDepositNet ',
}

export enum InvestmentAutoCloseMode {
  Manual = 'Manual',
  AutoClose = 'AutoClose',
}

export enum AgentChainSource {
  Investment = 'Investment',
  Offer = 'Offer',
  Configuration = 'Configuration',
  Platform = 'Platform',
  None = 'None',
}

export interface MoneyManagerSummary {
  investmentCountClosed: number;
  investmentCountActive: number;
  funds: number;
  balancePlatform: number;
  balanceInvestments: number;
  fundsManager: number;
  fundsInvestor: number;
  fundsCredit: number;
  profitOpen: number;
  profitTotal: number;
  profitClosed: number;
  investorDeposits: number;
  investorWithdrawals: number;
  diff: number;
}

export interface PublicProfile {
  avatarPath?: string;
}

export interface ManagerDto {
  id: number;
  ownerId: number;
  serverId: number;
  serverName?: string;
  accountId: number;
  name?: string;
  currencyDigits: number;
  status: ManagerStatus;
  isPublic: boolean;
  createdDt: Date;
  createdDtPlatform: Date;
  updateDt?: Date;
  updateDtPlatform?: Date;
  closedDt?: Date;
  currency?: string;
  configurationId?: number;
  configurationName?: string;
  isActive: boolean;
  investmentCountActive: number;
  funds: number;
  fundsInvestor: number;
  fundsCredit: number;
  fundsManager: number;
  profitTotal: number;
  summary?: MoneyManagerSummary;
  public?: PublicProfile;
}

export interface InvestmentArchiveMetadata {
  reason: ArchiveReason;
  autoCloseMode?: InvestmentAutoCloseMode;
  autoCloseLevel?: number;
  profitNet?: number;
  profitNetTrigger?: number;
  autoCloseSettingsDate?: Date;
  requestDate?: Date;
}

export interface AppliedAgent {
  login: number;
  serverId?: number;
  serverName?: string;
}

export interface ChoosedAgentChain {
  agents: AppliedAgent[];
  source: AgentChainSource;
}

export interface InvestmentDto {
  id: number;
  name?: string;
  ownerId: number;
  ownerServerId?: number;
  ownerServerName?: string;
  balance: number;
  credit: number;
  profitInterval: number;
  share?: number;
  profitNet: number;
  profitIntervalTotal: number;
  profitUndistributed: number;
  profitTotal: number;
  fundsTotal: number;
  offerId?: number;
  offerName?: string;
  managerId: number;
  managerName?: string;
  managerConfiguration?: number;
  isManagerOwned: boolean;
  tradingIntervalStart: Date;
  tradingIntervalEnd: Date;
  currency?: string;
  status: InvestmentStatus;
  createdDt: Date;
  activeDt?: Date;
  activeDtPlatform?: Date;
  closedDt?: Date;
  updateDt: Date;
  archiveMetadata: InvestmentArchiveMetadata;
  isActive: boolean;
  isPublic: boolean;
  agentIds?: string;
  choosedAgentChain?: ChoosedAgentChain;
}

export enum InvestmentRequestType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  CloseAccount = 'CloseAccount',
  CloseManager = 'CloseManager',
}

export enum InvestmentRequestStatus {
  Pending = 'Pending',
  Confirmed = 'Confirmed',
  Success = 'Success',
  Error = 'Error',
  Cancelled = 'Cancelled',
}

export enum OwnerType {
  System = 'System',
  Admin = 'Admin',
  User = 'User',
}

export enum ArchiveReasonSystem {
  Manual = 'Manual',
  AutoClose = 'AutoClose',
}

export enum InvestmentAutoCloseModeSystem {
  Disabled = 'Disabled',
  AbsoluteLoss = 'AbsoluteLoss',
  RelativeLossDepositNet = 'RelativeLossDepositNet',
}

export interface RequestSystemData {
  ownerType: OwnerType;
  isEntryDeposit?: boolean;
  archiveReason?: ArchiveReasonSystem;
  autoCloseMode?: InvestmentAutoCloseModeSystem;
  autoCloseLevel?: number;
  profitNet?: number;
  profitNetTrigger?: number;
  autoCloseSettingsDate?: Date;
}

export interface InvestmentRequestDtoInvestmentRequestDto {
  id: number;
  requestType: InvestmentRequestType;
  status: InvestmentRequestStatus;
  investmentId: number;
  investorName: string;
  managerId: number;
  managerName?: string;
  managerConfiguration?: number;
  currency?: string;
  platformOperationId?: number;
  amount?: number;
  requestTime: Date;
  comment?: string;
  skipTransactionFee: boolean;
  system: RequestSystemData;
}

export interface InvestmentCreateResultDto {
  investment: InvestmentDto;
  request: InvestmentRequestDtoInvestmentRequestDto;
}

export interface PammPageResultOfInvestmentDto {
  count: number;
  items: InvestmentDto[];
}

export function useAccountsList() {
  const axios = useAxios();

  return useQuery(['accounts'], async () => {
    const { data } = await axios.get<Account[]>('/accounts');

    return data;
  });
}

export function useAccountStatus() {
  const axios = useAxios();

  return useQuery(['accounts/status'], async () => {
    const { data } = await axios.get<AccountStatusType>('/accounts/status');

    return data;
  });
}

export function useAccountAndSubCreate() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation<CreateAccountInput, unknown, CreateAccountInput>(
    ['createAccountAndSubscription'],
    (payload) => axios.post('/accounts/subscription', payload),
    {
      onSuccess() {
        queryClient.invalidateQueries('accounts');
      },
    },
  );
}

export type CreateAccountInput = {
  leverage: number;
  currency: Currency;
  type: 'demo' | 'live';
  platform: 'mt4' | 'mt5';
  subscriptionId?: number;
};

export function useAccountCreate() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation<CreateAccountInput, unknown, CreateAccountInput>(
    ['createAccount'],
    (payload) => axios.post('/accounts', payload),
    {
      onSuccess() {
        queryClient.invalidateQueries('accounts');
      },
    },
  );
}

export function useSubscriptions() {
  const axios = useAxios();

  return useQuery(['accounts/subscriptions'], async () => {
    const { data } = await axios.get<UserServiceSubscription[]>(
      '/accounts/subscriptions',
    );

    return data;
  });
}

export function useUpdateSubscription() {
  const axios = useAxios();

  return useMutation<
    AxiosResponse<UserServiceSubscription>,
    unknown,
    { subscriptionId: number }
  >(['subscriptionId'], (payload) =>
    axios.post<
      { subscriptionId: number },
      AxiosResponse<UserServiceSubscription>
    >('/accounts/subscription/update', payload),
  );
}

export function usePammPublicManagers() {
  const axios = useAxios();

  return useQuery([`pammMoneyManager`], async () => {
    const { data } = await axios.get<ManagerPublicDto[]>(
      `accounts/pamm/public/managers`,
    );

    return data;
  });
}

export function usePammMoneyManagersAccounts() {
  const axios = useAxios();

  return useQuery([`pammMoneyManagersAccount`], async () => {
    const { data } = await axios.get<AccountDefinitionMaped[]>(
      `accounts/pamm/accounts/money_managers`,
    );

    return data;
  });
}

export function usePersonalMoneyManagersAccounts() {
  const axios = useAxios();

  return useQuery([`personalMoneyManagersAccount`], async () => {
    const { data } = await axios.get<InvestmentDto[]>(
      `accounts/pamm/accounts/personal_money_managers`,
    );

    return data;
  });
}
export function usePammInvestorAccounts() {
  const axios = useAxios();

  return useQuery([`pammInvestmentsAccounts`], async () => {
    const { data } = await axios.get<AccountDefinitionMaped[]>(
      `accounts/pamm/accounts/investments`,
    );

    return data;
  });
}

export function useCreatePammMoneyManager() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<ManagerDto>,
    unknown,
    Omit<PammAccountData, 'activeStep'>
  >(
    ['createPammMoneyManager'],
    (payload) =>
      axios.post<
        Omit<PammAccountData, 'activeStep'>,
        AxiosResponse<ManagerDto>
      >('/accounts/pamm/init/manager', payload),
    {
      onSuccess() {
        queryClient.invalidateQueries('pammMoneyManager');
      },
    },
  );
}

export function useCreatePammInvestments() {
  const axios = useAxios();

  return useMutation<
    AxiosResponse<InvestmentCreateResultDto>,
    unknown,
    Omit<PammInvestmentAccountData, 'activeStep'>
  >(['pammInvestor'], (payload) =>
    axios.post<
      Omit<PammInvestmentAccountData, 'activeStep'>,
      AxiosResponse<InvestmentCreateResultDto>
    >('/accounts/pamm/init/investment', payload),
  );
}

export function usePammManagerOffers(id: number) {
  const axios = useAxios();

  return useQuery([`managerOffers`], async () => {
    const { data } = await axios.get<ManagerOfferDto[]>(
      `accounts/pamm/manager/offers/${id}`,
    );

    return data;
  });
}

export function useCreateOffer() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<ManagerOfferDto>, unknown, CreateOfferDto>(
    ['offers'],
    (payload) =>
      axios.post<CreateOfferDto, AxiosResponse<ManagerOfferDto>>(
        'accounts/pamm/manager/offer',
        payload,
      ),
    {
      onSuccess() {
        queryClient.invalidateQueries('managerOffers');
      },
    },
  );
}

export function useUpdateOffer(id: number) {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<ManagerOfferDto>, unknown, UpdateOfferDto>(
    ['updateOffer'],
    (payload) =>
      axios.put<UpdateOfferDto, AxiosResponse<ManagerOfferDto>>(
        `accounts/pamm/manager/offer/${id}`,
        payload,
      ),
    {
      onSuccess() {
        queryClient.invalidateQueries('managerOffers');
      },
    },
  );
}

export function useCreatePromoIBLink() {
  const axios = useAxios();

  return useMutation<
    AxiosResponse<UserServiceSubscription>,
    unknown,
    { name: string }
  >(['createPromoIBLink'], (payload) =>
    axios.post<{ name: string }, AxiosResponse<UserServiceSubscription>>(
      '/accounts/ib/link/new',
      payload,
    ),
  );
}

export function useEnableIB() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<Account>,
    unknown,
    { enableIB: boolean; currency: Currency }
  >(
    ['enableIB'],
    (payload) =>
      axios.post<
        { enableIB: boolean; currency: Currency },
        AxiosResponse<Account>
      >('/accounts/ib/enable', payload),
    {
      onSuccess() {
        queryClient.invalidateQueries('ibtree');
      },
    },
  );
}

export function useIbTree() {
  const axios = useAxios();

  return useQuery(['ibtree'], async () => {
    const { data } = await axios.get<IbTreeDefinition[]>('/accounts/ib/tree');

    return data.filter(
      ({ ibId, referralIbId }) => Number(ibId) !== Number(referralIbId),
    );
  });
}

export function useIbReferralAccounts(referralId: number) {
  const axios = useAxios();

  return useQuery(['ibReferralAccounts', referralId], async () => {
    const { data } = await axios.get<Account[]>(
      `accounts/ib/referral/${referralId}`,
    );

    return data;
  });
}
