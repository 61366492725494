import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AccountsList } from './views/Accounts/AccountsList';
import { OpenAccount } from './views/Accounts/OpenAccount';
import { Auth } from './views/Auth/Auth';
import { Login } from './views/Auth/Login';
import { Registration, RegistrationByInvite } from './views/Auth/Registration';
import { Dashboard } from './views/Dashboard/Dashboard';
import { DashboardError } from './views/Dashboard/DashboardError';
import { Deposit } from './views/Deposit/Deposit';
import { IBMenu } from './views/IB/IBMenu';
import { OfferEdit } from './views/MoneyManagers/Offers/OfferEdit';
import { OffersList } from './views/MoneyManagers/Offers/OffersList';
import { OfferView } from './views/MoneyManagers/Offers/OfferView';
import { CreateMoneyManagerAccount } from './views/Pamm/MoneyManagerAccount/CreateMoneyManagerAccount';
import { CreateInvestment } from './views/Pamm/MoneyManagerAccount/Offers/CreateInvestment';
import { Offers } from './views/Pamm/MoneyManagerAccount/Offers/Offers';
import { PammDashboard } from './views/Pamm/PammDashboard';
import { ChangePassword } from './views/Profile/ChangePassword';
import { Profile } from './views/Profile/Profile';
import { TransfersDashboard } from './views/Transfers/TransfersDashboard';
import { TransfersForm } from './views/Transfers/TransfersForm';
import { TransfersList } from './views/Transfers/TransfersList';
import { SymbolsTable } from './views/Transparency/SymbolsTable';
import { Transparency } from './views/Transparency/Transparency';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
    errorElement: <DashboardError />,
    children: [
      {
        path: '',
        element: <Navigate to="accounts" />,
      },
      {
        path: 'accounts',
        element: <AccountsList />,
        children: [
          {
            path: 'new',
            element: <OpenAccount />,
          },
        ],
      },
      {
        path: 'deposit',
        element: <Deposit />,
      },
      {
        path: 'transfers',
        element: <TransfersDashboard />,
        children: [
          {
            path: ':fromLoginSid',
            element: <TransfersList />,
          },
          {
            path: ':fromLoginSid/new',
            element: <TransfersForm />,
          },
        ],
      },
      {
        path: 'transparency',
        element: <Transparency />,
      },
      {
        path: 'ib',
        element: <IBMenu />,
      },
      {
        path: 'profile',
        element: <Navigate to="/profile/information" />,
      },
      {
        path: 'profile/:tab',
        element: <Profile />,
        children: [
          {
            path: 'change-password',
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: 'pamm',
        element: <PammDashboard />,
        children: [
          {
            path: 'new',
            element: <CreateMoneyManagerAccount />,
          },
        ],
      },
      {
        path: 'pamm/manager/:id',
        element: <Offers />,
        children: [
          {
            path: 'offer/:offerId',
            element: <CreateInvestment />,
          },
        ],
      },
      {
        path: 'money_managers/manager/:id',
        element: <OffersList />,
        children: [
          {
            path: 'offer/:offerId/edit',
            element: <OfferEdit />,
          },
          {
            path: 'offer/new',
            element: <OfferEdit />,
          },
        ],
      },
      {
        path: 'money_managers/manager/:id/offer/:offerId/view',
        element: <OfferView />,
      },
    ],
  },
  {
    path: '/auth',
    element: <Auth />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'registration',
        children: [
          {
            path: '',
            element: <Registration />,
          },
          {
            path: ':inviterId',
            element: <RegistrationByInvite />,
          },
        ],
      },
    ],
  },
  {
    path: '/symbols',
    element: <SymbolsTable hideTransparency />,
  },
]);
