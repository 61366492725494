import React from 'react';

import { Modal, Paper, Typography } from '@mui/material';
import { map } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Account, useIbReferralAccounts } from '../../../api/accounts';
import { CircularProgressBox } from '../../../components/CircularProgress/CircularProgressBox';
import {
  BodyTableRow,
  HeadTableRow,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '../../../components/Table/Table';

export function ReferralAccounts({
  referralId,
  visible,
}: {
  referralId: number;
  visible: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) {
  const { data, isLoading } = useIbReferralAccounts(referralId);
  const { t } = useTranslation('referralAccounts');
  return (
    <Modal
      open={visible[0]}
      onClick={() => visible[1]((prev) => !prev)}
      sx={{
        p: 10,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {isLoading ? (
        <CircularProgressBox />
      ) : data?.length ? (
        <TableContainer
          component={Paper}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'scroll',
            overflowX: 'scroll',
            height: '100%',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <HeadTableRow>
                <TableCell>{t('login')}</TableCell>
                <TableCell>{t('type')}</TableCell>
                <TableCell>{t('leverage')}</TableCell>
                <TableCell>{t('currency')}</TableCell>
                <TableCell>{t('balance')}</TableCell>
                <TableCell>{t('credit')}</TableCell>
                <TableCell>{t('equity')}</TableCell>
                <TableCell>{t('margin')}</TableCell>
              </HeadTableRow>
            </TableHead>
            <TableBody>
              {map(data, (acc: Account) => (
                <BodyTableRow key={acc.login}>
                  <TableCell>{acc.login}</TableCell>
                  <TableCell>{acc.type}</TableCell>
                  <TableCell>{acc.leverage}</TableCell>
                  <TableCell>{acc.currency}</TableCell>
                  <TableCell>{acc.balance}</TableCell>
                  <TableCell>{acc.credit}</TableCell>
                  <TableCell>{acc.equity}</TableCell>
                  <TableCell>{acc.margin}</TableCell>
                </BodyTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography
          variant={'h6'}
          sx={{
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            display: 'flex',
            p: 4,
          }}
        >
          {`${t('emptyState', { id: referralId })}`}
        </Typography>
      )}
    </Modal>
  );
}
