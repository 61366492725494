import { useMutation, useQuery } from 'react-query';

import { useAxios } from '../providers/axios';

export type Profile = {
  id?: number;
  managerId?: number;

  /** iso-2 */
  country?: string;

  /** City */
  city?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  email?: string;
  referrer?: string;
  phone?: string;
  lead?: boolean;

  /** @format date-time */
  registrationDate?: string;

  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  promotedToClientAt?: string;

  /** @format date-time */
  lastLoginDate?: string;
  referralLink?: string;
  status?: string;
  secondaryStatus?: string;
  commpeakStatus?: string;
  cellxpertCxd?: string;
  title?: string;
  isIb?: boolean;
  isTrader?: boolean;
  enabled?: boolean;
  vat?: string;
  companyRegNumber?: string;
  companyJurisdiction?: string;

  /** Nationality country */
  nationality?: string;
  address?: string;
  zipCode?: string;
  clientType?: string;
  phoneCode?: string;

  /** LEI Number */
  lei?: string;

  /** Tax Residency Country */
  taxResidency?: string;

  /** Country Of Birth */
  countryOfBirth?: string;
  cityOfBirth?: string;

  /** @format date-time */
  birthDate?: string;
  language?: string;
  tags?: string[];

  /**
   * Client's custom fields
   * @example {"custom_field_1":"Hello!","custom_field_2":"Bonjour!"}
   */
  customFields?: Record<string, string>;
  partnerId?: number;
  emailVerified?: boolean;
  phoneVerified?: boolean;
  notificationPreferences?: string[];

  /** Is Test Profile */
  testProfile?: boolean;
  mifidCategorization?: string;

  /** Tax Identification Number */
  tin?: string;

  /** No TIN Reason */
  noTinReason?: string;

  /** Register Social Provider */
  registerSocialProvider?: string;
  pep?: boolean;
  riskLevel?: string;
  riskCategorization?: string;
  riskComment?: string;
  cid?: number;
  verified?: boolean;
  tradingStatus?: string;
  clientIp?: string;
  source?: string;
  firstDepositId?: number;

  /** @format date-time */
  firstDepositDate?: string;
  lastDepositId?: number;

  /** @format date-time */
  lastDepositDate?: string;
  maxLeverage?: number;

  /** @format date-time */
  lastTradedAt?: string;
  ibCampaignId?: number;

  /** Can request IB */
  canRequestIb?: boolean;

  /** Can create IB Links */
  canCreateIbLinks?: boolean;
  masterPartnerId?: number;
  entryPoint?: string;
  ibTreeDepth?: number;
  ib?: boolean;
  trader?: boolean;
};

export type LoginInput = {
  username: string;
  password: string;
};

export type AuthResponse = {
  accessToken: string;
};

export function useLogin() {
  const axios = useAxios();

  return useMutation<AuthResponse, Error, LoginInput>(
    ['login'],
    async (payload) => {
      const { data, status } = await axios.post('/auth/login', payload);
      if (status >= 200 && status < 300) {
        return data;
      }

      throw new Error(data?.message || 'Login failed');
    },
  );
}

export type RegistrationInput = {
  email: string;
  firstName: string;
  agreement: boolean;
  inviterId: string | null;
};

export function useRegistration() {
  const axios = useAxios();

  return useMutation<AuthResponse, Error, RegistrationInput>(
    ['registration'],
    async (payload) => {
      const { data, status } = await axios.post('/auth/registration', payload);

      if (status >= 200 && status < 300) {
        return data;
      }

      throw new Error(data?.message || 'Registration failed');
    },
  );
}

export type RegistrationCreateInput = {
  id: number;
};

export function useProfile() {
  const axios = useAxios();
  return useQuery(['profile'], async () => {
    const { data } = await axios.get<Profile>('/auth/profile');
    return data;
  });
}

export type ChangePasswordInput = {
  oldPassword: string;
  newPassword: string;
};

export function usePasswordChange() {
  const axios = useAxios();

  return useMutation<unknown, Error, ChangePasswordInput>(
    ['profile', 'password'],
    async (payload) => {
      const { data, status } = await axios.put('/auth/password', payload);
      if (status >= 200 && status < 300) {
        return data;
      }

      throw new Error(data?.message || 'Failed to change password');
    },
  );
}

export function useVerificationAccessToken() {
  const axios = useAxios();

  return useQuery<string>(['verification-access-token'], async () => {
    const { data } = await axios.post('/verification/access-token');
    return data.token;
  });
}
