import { useMemo } from 'react';
import * as React from 'react';

import { LoadingButton } from '@mui/lab';
import { MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

import { Account, useAccountsList } from '../../api/accounts';
import { useProfile } from '../../api/auth';
import { useDepositCreate } from '../../api/transactions';
import { useQueryParam } from '../../hooks/useQueryParam';
import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayout';

type FormValues = {
  amount: number;
  accountId: string;
};

const initialValues: FormValues = {
  amount: 100,
  accountId: '',
};

export function Deposit({
  selectedAccount = '',
}: {
  selectedAccount?: string;
}) {
  const profile = useProfile();
  const accounts = useAccountsList();
  const create = useDepositCreate();
  const [accountId, setAccountId] = useQueryParam('account');
  if (selectedAccount?.length && accountId !== selectedAccount) {
    setAccountId(selectedAccount);
  }

  const { t } = useTranslation('deposit');
  const filteredAccount = (
    (accounts?.data as (Account & { serverId: number })[]) || []
  ).filter(({ serverId }) => serverId !== 3 && serverId !== 6);

  if (!initialValues.accountId.length && accountId) {
    initialValues.accountId = accountId;
  }
  filteredAccount.map((account) => {
    if (String(account.login) === accountId) {
    }
  });
  const checkoutUrl = useMemo(() => {
    const url = new URL('https://checkout.bridgerpay.com/v2/');
    if (create.data) {
      url.searchParams.append('orderId', create.data.transactionId);
      url.searchParams.append('cashierKey', create.data.cashierKey);
    } else {
      return undefined;
    }

    if (create.variables) {
      url.searchParams.append('amount', create.variables.amount.toString());
      url.searchParams.append('currency', create.variables.currency);
    }

    url.searchParams.append('currencyLock', 'true');
    url.searchParams.append('amountLock', 'true');
    if (profile.data?.country) {
      url.searchParams.append('country', profile.data.country);
    }
    if (profile.data?.firstName) {
      url.searchParams.append('firstName', profile.data.firstName);
    }
    if (profile.data?.lastName) {
      url.searchParams.append('lastName', profile.data.lastName);
    }
    if (profile.data?.phone) {
      url.searchParams.append('phone', profile.data.phone);
    }
    if (profile.data?.email) {
      url.searchParams.append('email', profile.data.email);
    }
    if (profile.data?.address) {
      url.searchParams.append('address', profile.data.address);
    }
    if (profile.data?.city) {
      url.searchParams.append('city', profile.data.city);
    }
    if (profile.data?.zipCode) {
      url.searchParams.append('zipCode', profile.data.zipCode);
    }
    if (profile.data?.language) {
      url.searchParams.append('language', profile.data.language);
    }

    url.searchParams.append('hideHeader', 'true');
    url.searchParams.append('hideLanguagesDropdown', 'true');
    url.searchParams.append('theme', 'light');

    return url.toString();
  }, [create]);

  return (
    <DashboardLayout
      title={t('deposit')}
      isLoading={profile.isLoading || accounts.isLoading}
    >
      {checkoutUrl ? (
        <iframe
          src={checkoutUrl}
          width="100%"
          height="95%"
          style={{ border: 0 }}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            const account = accounts.data?.find(
              (a) => a.id === values.accountId,
            );
            if (account) {
              return create.mutateAsync({
                amount: values.amount,
                currency: account.currency,
                login: account.login,
                serverId: account.serverId,
              });
            }

            throw new Error(t('accountNotFound'));
          }}
        >
          <Form style={{ backgroundColor: 'transparent' }}>
            <Field
              component={TextField}
              type="number"
              name="amount"
              label={t('amount')}
              margin="normal"
              fullWidth
            />
            <Field
              select
              component={TextField}
              name="accountId"
              label={t('account')}
              margin="normal"
              fullWidth
            >
              {filteredAccount.map((account) => (
                <MenuItem
                  key={account.id}
                  value={account.id}
                  selected={Boolean(String(account.login) === accountId)}
                >
                  {`${account.type} ${account.login}(${account.balance} ${account.currency})`}
                </MenuItem>
              ))}
            </Field>
            <LoadingButton
              sx={{ mt: 1 }}
              loading={create.isLoading || create.isSuccess}
              type="submit"
              variant="contained"
              color={create.isError ? 'error' : 'primary'}
              fullWidth
            >
              {t('deposit')}
            </LoadingButton>
          </Form>
        </Formik>
      )}
    </DashboardLayout>
  );
}
