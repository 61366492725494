import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayout';
import { MoneyManagersList } from '../MoneyManagers/MoneyManagersList';

import { Managers } from './Managers';

export function PammDashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation('pammDashboard');

  return (
    <DashboardLayout
      title={t('title')}
      actions={
        <Button
          variant="contained"
          color="secondary"
          sx={{ alignSelf: 'flex-end', my: 2 }}
          onClick={() => navigate('/pamm/new')}
        >
          {t('becomeMM')}
        </Button>
      }
    >
      <MoneyManagersList />
      <Managers />
    </DashboardLayout>
  );
}
