import React, { PropsWithChildren } from 'react';

import { Button, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

import { PammAccountData } from '../CreateMoneyManagerAccount';

const leverage = [1, 2, 5, 10, 33, 50, 100];

export function CreateAccount({
  accountData,
}: PropsWithChildren & {
  accountData: [
    PammAccountData,
    React.Dispatch<React.SetStateAction<PammAccountData>>,
  ];
}) {
  const { t } = useTranslation('createManagerStepper');
  return (
    <Formik
      initialValues={accountData[0]}
      onSubmit={(values) =>
        accountData[1]((prev) => ({ ...prev, ...values, activeStep: 2 }))
      }
    >
      <Form>
        <Field
          component={TextField}
          select
          name="leverage"
          label={t('leverage')}
          margin="normal"
          fullWidth
        >
          {leverage.map((value) => (
            <MenuItem key={value} value={value}>
              {`1:${value}`}
            </MenuItem>
          ))}
        </Field>
        <Field
          component={TextField}
          select
          name="currency"
          label={t('currency')}
          margin="normal"
          fullWidth
        >
          <MenuItem value="USD">USD</MenuItem>
        </Field>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            disabled={accountData[0].activeStep === 0}
            onClick={() => {
              accountData[1]((prev) => ({
                ...prev,
                activeStep: 0,
              }));
            }}
            sx={{ mr: 1 }}
          >
            {t('back')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button type="submit" variant="contained">
            {t('next')}
          </Button>
        </Box>
      </Form>
    </Formik>
  );
}
