import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Button, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePammManagerOffers } from '../../../api/accounts';
import { DataGridStyled } from '../../../components/Grid/DataGrid';
import {
  CustomNoRows,
  CustomPagination,
} from '../../../components/Grid/Pagination';
import { MoreButton } from '../../../components/MoreButton/MoreButton';
import { DashboardLayout } from '../../../layouts/Dashboard/DashboardLayout';

export function OffersList() {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = usePammManagerOffers(Number(id));
  const { t } = useTranslation('privateMM');
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name'),
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'tradingInterval',
      headerName: t('tradingInterval'),
      type: 'string',
      minWidth: 200,
    },
    {
      field: 'minInitialInvestment',
      headerName: t('minInitialInvestment'),
      minWidth: 50,
      type: 'number',
      flex: 1,
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      renderCell: (params) => {
        const view = () => {
          window.location.href = `/money_managers/manager/${params.row.managerId}/offer/${params.row.id}/view`;
        };
        const edit = () => {
          window.location.href = `/money_managers/manager/${params.row.managerId}/offer/${params.row.id}/edit`;
        };

        return (
          <MoreButton key={params.row.id}>
            <MenuItem onClick={view} disableRipple>
              {t('view')}
            </MenuItem>
            <MenuItem onClick={edit} disableRipple>
              {t('edit')}
            </MenuItem>
          </MoreButton>
        );
      },
    },
  ];

  const rows = data
    ? data.map(({ id, name, settings, managerId }) => ({
        id,
        managerId,
        name,
        minInitialInvestment: settings?.minInitialInvestment,
        tradingInterval: `${settings?.tradingInterval?.count} ${settings?.tradingInterval?.type}`,
      }))
    : [];
  return (
    <DashboardLayout
      title={t('pammManagerOffers')}
      isLoading={isLoading}
      actions={
        data?.length ? (
          <Button
            href="offer/new"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            {t('createNewOffer')}
          </Button>
        ) : null
      }
    >
      {data?.length ? (
        <DataGridStyled
          rowHeight={43}
          columnHeaderHeight={39}
          rows={rows}
          loading={isLoading}
          columns={columns}
          slots={{
            pagination: CustomPagination,
            noRowsOverlay: () =>
              CustomNoRows({
                message: t('offersNotExist'),
              }),
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          disableColumnSelector
          autoHeight
        />
      ) : (
        <Box
          sx={{
            p: 5,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              height: '110px',
              width: '110px',
              borderRadius: '110px',
              backgroundColor: '#FFFFFF',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              m: 5,
            }}
          >
            <PlaylistAddCheckIcon sx={{ width: '57px', height: '57px' }} />
          </Box>
          <Typography variant={'h5'}>{t('offersNotExist')}</Typography>
          <Button
            href="offer/new"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ minWidth: '172px', minHeight: '38px', my: 5 }}
          >
            {t('createNewOffer')}
          </Button>
        </Box>
      )}
    </DashboardLayout>
  );
}
