import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePammManagerOffers } from '../../../api/accounts';
import {
  BodyTableRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '../../../components/Table/Table';
import { DashboardLayout } from '../../../layouts/Dashboard/DashboardLayout';
import { TabLayout } from '../../../layouts/Tab/TabLayout';

export function OfferView() {
  const { id, offerId } = useParams<{ id: string; offerId: string }>();
  const { data, isLoading } = usePammManagerOffers(Number(id));
  const { t } = useTranslation('privateMM');

  const isOffer = useMemo(
    () => (data ? data.find(({ id }) => id === Number(offerId)) : false),
    [data],
  );

  return (
    <DashboardLayout title={t('offerView')} isLoading={isLoading}>
      {isOffer ? (
        <TableContainer
          component={TabLayout}
          isLoading={isLoading}
          sx={{ padding: 0 }}
        >
          <Table>
            <TableBody>
              {
                <>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('offerName')}
                    </TableCell>
                    <TableCell>{isOffer?.name}</TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('description')}
                    </TableCell>
                    <TableCell>{isOffer?.description}</TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('currency')}
                    </TableCell>
                    <TableCell>{isOffer?.currency}</TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('activeInvestmentCount')}
                    </TableCell>
                    <TableCell>{isOffer?.activeInvestmentCount}</TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('active')}
                    </TableCell>
                    <TableCell>{isOffer?.isActive.toString()}</TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('tradingIntervalType')}
                    </TableCell>
                    <TableCell>
                      {isOffer?.settings?.tradingInterval?.type}
                    </TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('tradingInterval')}
                    </TableCell>
                    <TableCell>
                      {isOffer?.settings?.tradingInterval?.count}
                    </TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('minDeposit')}
                    </TableCell>
                    <TableCell>{isOffer?.settings?.minDeposit}</TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('minWithdrawal')}
                    </TableCell>
                    <TableCell>{isOffer?.settings?.minWithdrawal}</TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('minInitialInvestment')}
                    </TableCell>
                    <TableCell>
                      {isOffer?.settings?.minInitialInvestment}
                    </TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                  <BodyTableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: '#8896A0' }}
                    >
                      {t('performanceFeeMode')}
                    </TableCell>
                    <TableCell>
                      {isOffer?.settings?.performanceFees?.mode}
                    </TableCell>
                    <TableCell></TableCell>
                  </BodyTableRow>
                </>
              }
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </DashboardLayout>
  );
}
