import React from 'react';

import { useTranslation } from 'react-i18next';

import { useProfile } from '../../api/auth';
import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayout';

import { IBDashboard } from './Dashboard/IBDashboard';
import { IBConfirmAgreement } from './IBConfirmAgreement';

export function IBMenu() {
  const dataProfile = useProfile();
  const { t } = useTranslation('ibMenu');
  return (
    <DashboardLayout
      title={t('title')}
      isLoading={dataProfile.isLoading || dataProfile.isLoading}
    >
      {dataProfile.data?.isIb ? <IBDashboard /> : <IBConfirmAgreement />}
    </DashboardLayout>
  );
}
