import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Box, Paper, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ReferralLink({ referralLink }: { referralLink: string }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('ibMenu');
  const handleClick = () => {
    navigator.clipboard.writeText(referralLink);
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <Box
      component={Paper}
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onClick={handleClick}
    >
      <Typography variant="h6">{referralLink}</Typography>
      <ContentCopyIcon />
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert severity="success" sx={{ width: '100%' }} onClose={handleClose}>
          {t('linkCopied')}
        </Alert>
      </Snackbar>
    </Box>
  );
}
