import { useCallback } from 'react';

import { ErrorHandler } from '@sumsub/websdk';
import SumSubWebSdk from '@sumsub/websdk-react';

import { useVerificationAccessToken, useProfile } from '../../api/auth';
import { TabLayout } from '../../layouts/Tab/TabLayout';

export function Verification() {
  const profile = useProfile();
  const accessToken = useVerificationAccessToken();

  const handleError = useCallback(
    ((error) => {
      console.error(error);
    }) as ErrorHandler,
    [],
  );

  const handleExpired = useCallback(() => {
    return accessToken.refetch().then(({ data }) => {
      if (!data) {
        return Promise.reject(new Error('SumSub token is empty'));
      }

      return data;
    });
  }, []);

  return (
    <TabLayout
      isLoading={profile.isLoading || accessToken.isLoading}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
      }}
    >
      {profile.data && accessToken.data && (
        <SumSubWebSdk
          style={{ display: 'flex', flex: '1 1 auto' }}
          accessToken={accessToken.data}
          expirationHandler={handleExpired}
          config={{
            email: profile.data?.email,
            phone: profile.data?.phone,
          }}
          options={{ addViewportTag: true, adaptIframeHeight: false }}
          onError={handleError}
        />
      )}
    </TabLayout>
  );
}
