import { useTranslation } from 'react-i18next';

import { useTree } from '../../api/merkle';
import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayout';

import { SymbolsTable } from './SymbolsTable';

export function Transparency() {
  const lastCommited = useTree('OTS_PENDING');
  const lastVerified = useTree('OTS_STAMPED');
  const { t } = useTranslation('transparency');

  return (
    <DashboardLayout
      title={t('transparency')}
      isLoading={lastCommited.isLoading || lastVerified.isLoading}
    >
      <SymbolsTable
        lastCommited={lastCommited?.data?.updatedAt}
        lastVerified={lastVerified?.data?.updatedAt}
      />
    </DashboardLayout>
  );
}
