import { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

import { ChangePasswordInput, usePasswordChange } from '../../api/auth';
import { ModalLayout } from '../../layouts/Modal/ModalLayout';

type FormValues = ChangePasswordInput & { confirmPassword: string };

const initialValues: FormValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const validationSchema = yup.object({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

export function ChangePassword() {
  const change = usePasswordChange();

  useEffect(() => {
    // if (change.isSuccess) {
    //   console.log('success');
    //   navigate('/profile');
    //   // TODO add alert
    // } else {
    //   console.log('not success');
    // }
  }, [change.isSuccess]);

  return (
    <ModalLayout title="Change Password">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => change.mutateAsync(values)}
      >
        <Form>
          <input
            style={{ display: 'none' }}
            type="text"
            name="username"
            autoComplete="username"
          />
          <Field
            component={TextField}
            type="password"
            name="oldPassword"
            label="Current Password"
            margin="normal"
            error={change.isError}
            helperText={change?.error?.message}
            autoComplete="current-password"
            fullWidth
          />
          <Field
            component={TextField}
            type="password"
            name="newPassword"
            label="New Password"
            margin="normal"
            error={change.isError}
            autoComplete="new-password"
            fullWidth
          />
          <Field
            component={TextField}
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            margin="normal"
            error={change.isError}
            autoComplete="new-password"
            fullWidth
          />
          <LoadingButton
            loading={change.isLoading || change.isSuccess}
            type="submit"
            variant="contained"
            fullWidth
          >
            Change Password
          </LoadingButton>
        </Form>
      </Formik>
    </ModalLayout>
  );
}
