import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Account, useAccountsList } from '../../api/accounts';
import {
  BodyTableRow,
  HeadTableRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '../../components/Table/Table';
import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayout';
import { filterAccounts } from '../../utils/accounts';

export function TransfersDashboard() {
  const navigate = useNavigate();
  const accounts = useAccountsList();

  return (
    <DashboardLayout title="Transfers">
      {accounts.isLoading && <p>Loading accounts...</p>}
      {accounts.data && !accounts.isLoading && !accounts.error && (
        <TableContainer component={Paper} sx={{ my: 2 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <HeadTableRow>
                <TableCell>Account ID</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Margin</TableCell>
              </HeadTableRow>
            </TableHead>
            <TableBody>
              {filterAccounts(accounts.data, 'live').map((account: Account) => (
                <BodyTableRow
                  key={account.id}
                  onClick={() => navigate(`/transfers/${account.id}`)}
                >
                  <TableCell component="th" scope="row">
                    {account.login}
                  </TableCell>
                  <TableCell>{account.balance}</TableCell>
                  <TableCell>{account.margin}</TableCell>
                </BodyTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DashboardLayout>
  );
}
