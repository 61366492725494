import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, TextField, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useEnableIB } from '../../api/accounts';
import { Currency } from '../../api/general';

export function IBConfirmAgreement() {
  const enableIB = useEnableIB();
  const { t } = useTranslation('ibMenu');
  const [currency] = useState<Currency>('USD');

  return (
    <Box
      component="form"
      sx={{
        alignItems: 'center',
        alignSelf: 'center',
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Box>
        <TextField
          select
          label={t('select')}
          defaultValue="USD"
          helperText={t('applyIb')}
        >
          <MenuItem value="USD">USD</MenuItem>
        </TextField>
      </Box>
      <LoadingButton
        loading={enableIB.isLoading || enableIB.isSuccess}
        disabled={!confirm}
        variant="contained"
        color="success"
        size="small"
        fullWidth
        onClick={() =>
          enableIB.mutate({
            enableIB: true,
            currency,
          })
        }
      >
        {t('continue')}
      </LoadingButton>
    </Box>
  );
}
