import {
  registerLocale as registerLanguage,
  getName as getLanguageName,
} from '@cospired/i18n-iso-languages';
import enLanguages from '@cospired/i18n-iso-languages/langs/en.json';
import {
  registerLocale as registerCountry,
  getName as getCountryName,
} from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';

registerLanguage(enLanguages);
registerCountry(enCountries);

export { getCountryName, getLanguageName };
