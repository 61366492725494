import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { usePersonalMoneyManagersAccounts } from '../../api/accounts';
import { CircularProgressBox } from '../../components/CircularProgress/CircularProgressBox';
import { DataGridStyled } from '../../components/Grid/DataGrid';
import {
  CustomNoRows,
  CustomPagination,
} from '../../components/Grid/Pagination';

export function MoneyManagersList() {
  const { data, isLoading } = usePersonalMoneyManagersAccounts();
  const { t } = useTranslation('privateMM');
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('manager'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'ownerId',
      headerName: t('ownerAccount'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'accountId',
      headerName: t('pammAccount'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'currency',
      headerName: t('currency'),
      type: 'string',
      minWidth: 20,
      flex: 1,
    },
    {
      field: 'funds',
      headerName: t('funds'),
      minWidth: 50,
      type: 'number',
      flex: 1,
    },
    {
      field: 'fundsInvestor',
      headerName: t('tradeResult'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'investmentCountActive',
      headerName: t('investors'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'fundsManager',
      headerName: t('ownFunds'),
      minWidth: 50,
      type: 'number',
      flex: 1,
    },
    {
      field: 'isPublic',
      headerName: t('public'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'createdDt',
      headerName: t('created'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: t('active'),
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          window.location.href = `/money_managers/manager/${params.row.id}`;
        };

        return (
          <Button variant="contained" color="secondary" onClick={onClick}>
            {t('view')}
          </Button>
        );
      },
    },
  ];

  const rows = data
    ? data.map((acc) => ({
        ...acc,
        isActive: acc.isActive ? t('active') : t('inactive'),
        isPublic: acc.isPublic ? t('public') : t('private'),
      }))
    : [];
  return isLoading ? (
    <CircularProgressBox />
  ) : data?.length ? (
    <DataGridStyled
      rowHeight={43}
      columnHeaderHeight={39}
      rows={rows}
      loading={isLoading}
      columns={columns}
      slots={{
        pagination: CustomPagination,
        noRowsOverlay: () =>
          CustomNoRows({
            message: t('mmNotExist'),
          }),
      }}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10]}
      disableColumnSelector
      autoHeight
    />
  ) : (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: '110px',
          width: '110px',
          borderRadius: '110px',
          backgroundColor: '#FFFFFF',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          m: 5,
        }}
      >
        <QueryStatsIcon sx={{ width: '57px', height: '57px' }} />
      </Box>
      <Typography variant={'h5'}>{t('mmNotExist')}</Typography>
      <Button
        href="new"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ minWidth: '172px', minHeight: '38px', my: 5 }}
      >
        {t('becomeMoneyManager')}
      </Button>
    </Box>
  );
}
