import React, { useState } from 'react';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePammInvestorAccounts } from '../../../../api/accounts';
import { Currency } from '../../../../api/general';
import { ModalLayout } from '../../../../layouts/Modal/ModalLayout';

import { Confirmation } from './Stepper/Confirmation';
import { CreateAccount } from './Stepper/CreateAccount';
import { Investment } from './Stepper/Investment';
import { MoneyManager } from './Stepper/MoneyManager';
import { OfferDetails } from './Stepper/OfferDetails';

export interface PammInvestmentAccountData {
  name?: string;
  ownerId: number;
  managerId: number;
  offerId: number;
  investment?: number;
  leverage: number;
  currency: Currency;
  activeStep: 0 | 1 | 2 | 3 | 4 | 5;
}

export function CreateInvestment() {
  const { id, offerId } = useParams<{ id: string; offerId: string }>();
  const { data, isLoading } = usePammInvestorAccounts();
  const { t } = useTranslation('createInvestment');

  const steps = [
    t('stepOfferDetails'),
    t('stepSelectAccount'),
    t('stepCreateAccount'),
    t('stepInvestment'),
    t('stepConfirmation'),
  ];
  const pammAccountData = useState<PammInvestmentAccountData>({
    name: '',
    investment: 0,
    managerId: Number(id),
    offerId: Number(offerId),
    ownerId: 0,
    leverage: 100,
    currency: 'USD',
    activeStep: 0,
  });

  function stepSwitch() {
    switch (pammAccountData[0].activeStep) {
      case 0:
        return <OfferDetails accountData={pammAccountData}></OfferDetails>;
      case 1:
        return (
          <MoneyManager
            moneyManagersAccounts={data || []}
            accountData={pammAccountData}
          ></MoneyManager>
        );
      case 2:
        return <CreateAccount accountData={pammAccountData}></CreateAccount>;
      case 3:
        return <Investment accountData={pammAccountData}></Investment>;
      case 4:
        return <Confirmation accountData={pammAccountData}></Confirmation>;
    }
  }

  return (
    <ModalLayout title={t('title')} isLoading={isLoading}>
      <Stepper
        activeStep={pammAccountData[0].activeStep}
        sx={{
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
        }}
      >
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} icon={null}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box>{stepSwitch()}</Box>
    </ModalLayout>
  );
}
