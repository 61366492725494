import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useProfile } from '../../api/auth';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  BodyTableRow,
} from '../../components/Table/Table';
import { TabLayout } from '../../layouts/Tab/TabLayout';
import { getLanguageName, getCountryName } from '../../utils/i18n';

export function Information() {
  const profile = useProfile();
  const { t } = useTranslation('profile');

  return (
    <TableContainer
      component={TabLayout}
      isLoading={profile.isLoading}
      sx={{ padding: 0 }}
    >
      <Table>
        <TableBody>
          {
            <>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('firstName')}
                </TableCell>
                <TableCell>{profile.data?.firstName}</TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('lastName')}
                </TableCell>
                <TableCell>{profile.data?.lastName}</TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('clientId')}
                </TableCell>
                <TableCell>{profile.data?.id}</TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('dateOfBirth')}
                </TableCell>
                <TableCell>{profile.data?.birthDate}</TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('password')}
                </TableCell>
                <TableCell>{profile.data ? '********' : ''}</TableCell>
                <TableCell>
                  <Link href="change-password" color={'#8896A0'}>
                    {t('change')}
                  </Link>
                </TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('email')}
                </TableCell>
                <TableCell>{profile.data?.email}</TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('mobileNumber')}
                </TableCell>
                <TableCell>{profile.data?.phone}</TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('communicationLanguage')}
                </TableCell>
                <TableCell>
                  {profile.data?.language
                    ? getLanguageName(
                        profile.data?.language,
                        profile.data?.language,
                      )
                    : ''}
                </TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
              <BodyTableRow>
                <TableCell component="th" scope="row" sx={{ color: '#8896A0' }}>
                  {t('CountryOfResidence')}
                </TableCell>
                <TableCell>
                  {profile.data?.country && profile.data?.language
                    ? getCountryName(
                        profile.data?.country,
                        profile.data?.language,
                        { select: 'official' },
                      )
                    : ''}
                </TableCell>
                <TableCell></TableCell>
              </BodyTableRow>
            </>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
