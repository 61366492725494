import { ComponentProps, PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { LoadingLayout } from './LoadingLayout';

export function BaseLayout({
  isLoading = false,
  children,
  ...props
}: PropsWithChildren<{ isLoading?: boolean } & ComponentProps<typeof Box>>) {
  return (
    <Box {...props}>
      <CssBaseline />
      <LoadingLayout isLoading={isLoading}>{children}</LoadingLayout>
    </Box>
  );
}
