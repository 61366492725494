import { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Typography, Link } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { LoginInput, useLogin } from '../../api/auth';
import { useAuth } from '../../providers/auth';

const validationSchema = yup.object({
  username: yup.string().email().required(),
  password: yup.string().required(),
});

const initialValues: LoginInput = {
  username: '',
  password: '',
};

export function Login() {
  const auth = useAuth();
  const login = useLogin();
  const navigate = useNavigate();
  const { t } = useTranslation('login');

  useEffect(() => {
    if (login.data?.accessToken) {
      auth.setToken(login.data.accessToken);
      navigate('/');
    }
  }, [login.isSuccess]);

  useEffect(() => {
    if (login.isError) {
      auth.setToken(null);
    }
  }, [login.isError]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => login.mutateAsync(values)}
    >
      <Form noValidate>
        <Typography variant="h4" align="center">
          {t('login')}
        </Typography>
        <Field
          component={TextField}
          type="email"
          name="username"
          label={t('email')}
          margin="normal"
          autoComplete="username"
          autoFocus
          fullWidth
        />
        <Field
          component={TextField}
          type="password"
          name="password"
          autoComplete="current-password"
          label={t('password')}
          margin="normal"
          fullWidth
        />
        <LoadingButton
          sx={{ mt: 1 }}
          loading={login.isLoading || login.isSuccess}
          type="submit"
          color={login.isError ? 'error' : 'primary'}
          fullWidth
        >
          {t('login')}
        </LoadingButton>
        <Typography margin={1} textAlign="center">
          {t('or')}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Link href="/auth/registration">{t('signUp')}</Link>
        </Box>
      </Form>
    </Formik>
  );
}
