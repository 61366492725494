import { PropsWithChildren } from 'react';

import { Box, CircularProgress, Paper, PaperProps } from '@mui/material';

export function TabLayout({
  isLoading,
  children,
  sx,
  ...props
}: PropsWithChildren<
  PaperProps & {
    isLoading?: boolean;
  }
>) {
  return (
    <Paper
      sx={{
        width: { sm: 510, xs: '100%' },
        ...sx,
      }}
      {...props}
    >
      {isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </Paper>
  );
}
