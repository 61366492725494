import { useEffect, useState } from 'react';
export enum LocalStorageKeys {
  auth_token = 'auth_token',
  inviterId = 'inviterId',
}

export function useLocalStorage(
  key: LocalStorageKeys,
): [string | null, (value: string | null) => void] {
  const [value, setValue] = useState(() => localStorage.getItem(key));

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
}
