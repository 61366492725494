import { PropsWithChildren, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from '../../components/Typography/PageHeader';

export function ModalLayout({
  title,
  isLoading,
  children,
}: PropsWithChildren<{
  title: string;
  isLoading?: boolean;
}>) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClose = useCallback(() => navigate(-1), [navigate]);

  return (
    <Drawer
      open={true}
      onClose={handleClose}
      anchor="right"
      PaperProps={{
        sx: {
          borderTop: `8px solid ${theme.palette.secondary.main}`,
          padding: theme.spacing(4),
          width: { xs: '100%', sm: '50%', md: '40%', lg: '30%' },
        },
      }}
    >
      <Grid container alignItems="center">
        <Grid item>
          <PageHeader variant="h1">{title}</PageHeader>
        </Grid>
        <Grid item flexGrow={1} />
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', right: 0, top: 0, mr: 2, mt: 3 }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      {isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </Drawer>
  );
}
