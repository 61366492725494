import React, { PropsWithChildren } from 'react';

import { Button, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

import { AccountDefinitionMaped } from '../../../../api/accounts';
import { PammAccountData } from '../CreateMoneyManagerAccount';

export function MoneyManager({
  moneyManagersAccounts,
  accountData,
}: PropsWithChildren & {
  moneyManagersAccounts: AccountDefinitionMaped[];
  accountData: [
    PammAccountData,
    React.Dispatch<React.SetStateAction<PammAccountData>>,
  ];
}) {
  const { t } = useTranslation('createManagerStepper');
  return (
    <Formik
      initialValues={accountData[0]}
      onSubmit={(values) =>
        accountData[1]((prev) => ({
          ...prev,
          ...values,
          isPublic: prev.isPublic,
          activeStep: values.ownerId ? 2 : 1,
        }))
      }
    >
      <Form>
        <Field
          component={TextField}
          select
          name="ownerId"
          label={t('managerAccount')}
          margin="normal"
          fullWidth
        >
          <MenuItem value={0}>Create new manager account</MenuItem>
          {moneyManagersAccounts.map((item) => (
            <MenuItem value={item.login} key={item.id}>
              {item.login} {item.name}
            </MenuItem>
          ))}
        </Field>
        <Field
          component={TextField}
          name="name"
          label={t('name')}
          margin="normal"
          fullWidth
        ></Field>
        <Field
          component={TextField}
          name="description"
          label={t('description')}
          margin="normal"
          fullWidth
        ></Field>
        <Field
          component={CheckboxWithLabel}
          name="isPublic"
          type="checkbox"
          Label={{
            label: (
              <Typography variant="body1" component="span">
                {t('public')}
              </Typography>
            ),
          }}
          margin="normal"
          fullWidth
          checked={accountData[0].isPublic}
          onChange={() =>
            accountData[1]((prev) => ({ ...prev, isPublic: !prev.isPublic }))
          }
        ></Field>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            disabled={accountData[0].activeStep === 0}
            onClick={() => {
              accountData[1]((prev) => ({
                ...prev,
                activeStep: 0,
              }));
            }}
            sx={{ mr: 1 }}
          >
            {t('back')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button type="submit" variant="contained">
            {t('next')}
          </Button>
        </Box>
      </Form>
    </Formik>
  );
}
