import React, { PropsWithChildren } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { usePammManagerOffers } from '../../../../../api/accounts';
import { PammInvestmentAccountData } from '../CreateInvestment';

export function OfferDetails({
  accountData,
}: PropsWithChildren & {
  accountData: [
    PammInvestmentAccountData,
    React.Dispatch<React.SetStateAction<PammInvestmentAccountData>>,
  ];
}) {
  const { data } = usePammManagerOffers(accountData[0].managerId);
  const { t } = useTranslation('offerStepper');
  if (!data?.length) {
    return <Typography>{t('wrongOfferData')}</Typography>;
  }
  const [managerOffer] = data.filter(({ id }) => id === accountData[0].offerId);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('offerName')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>{managerOffer.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>
              {t('tradingIntervalType')}
            </TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {managerOffer.settings?.tradingInterval?.type}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>
              {t('tradingIntervalPeriod')}
            </TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {managerOffer.settings?.tradingInterval?.count}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('minDeposit')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {managerOffer.settings?.minDeposit}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('minWithdrawal')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {managerOffer.settings?.minWithdrawal}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>
              {t('minInitialInvestment')}
            </TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {managerOffer.settings?.minInitialInvestment}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('performanceFees')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {/*{managerOffer.settings?.minInitialInvestment}*/}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('managementFees')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {/*{managerOffer.settings?.minInitialInvestment}*/}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('depositFees')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {/*{managerOffer.settings?.minInitialInvestment}*/}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('withdrawalFees')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {/*{managerOffer.settings?.minInitialInvestment}*/}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={accountData[0].activeStep === 0}
          onClick={() => {
            accountData[1]((prev) => ({
              ...prev,
              activeStep: prev.ownerId ? 0 : 1,
            }));
          }}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <LoadingButton
          variant="contained"
          onClick={() =>
            accountData[1]((prev) => ({
              ...prev,
              activeStep: 1,
            }))
          }
        >
          {t('continue')}
        </LoadingButton>
      </Box>
    </TableContainer>
  );
}
