import { createContext, PropsWithChildren, useContext } from 'react';

import noop from 'lodash-es/noop';

import { LocalStorageKeys, useLocalStorage } from '../hooks/useLocalStorage';

type AuthState = 'AUTHORIZED' | 'UNAUTHORIZED';

type Context = {
  token: string | null;
  setToken(token: string | null): void;
  authState: AuthState;
};

export const AuthContext = createContext<Context>({
  token: null,
  setToken: noop,
  authState: 'UNAUTHORIZED',
});

export function AuthProvider({ children }: PropsWithChildren<unknown>) {
  const [token, setToken] = useLocalStorage(LocalStorageKeys.auth_token);
  const authState = token ? 'AUTHORIZED' : 'UNAUTHORIZED';

  return (
    <AuthContext.Provider value={{ token, setToken, authState }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
