import { useMutation } from 'react-query';

import { useAxios } from '../providers/axios';

export interface TransferRequest {
  /** @example "2-1000" */
  fromLoginSid?: string;
  /** @example "2-1000" */
  toLoginSid?: string;
  /** @example "approved" */
  status?: string;
  /** @format float */
  amount?: number;
  currency?: string;
  /**
   * Transfer Type
   * @example "internal transfer"
   */
  type?: string;
}

export interface TransferDefinition {
  id?: number;
  status?: string;
  type?: string;
  /** @format float */
  amount?: number;
  currency?: string;
  createdAt?: string;
  fromLoginSid?: string;
  toLoginSid?: string;
}

export interface TransferNewCreate {
  fromSidLogin: string;
  toSidLogin: string;
  amount: number;
}

export function useTransfersList(loginId: string) {
  const axios = useAxios();
  return useMutation<TransferDefinition[], unknown, TransferRequest>(
    ['transfersList', loginId],
    async (payload) => {
      const { data, status } = await axios.post('/transfers/list', {
        ...payload,
        toLoginSid: loginId,
        fromLoginSid: loginId,
      });
      if (status >= 200 && status < 300) {
        return data;
      }
      throw new Error(data?.message || 'Wrong requested data');
    },
  );
}

export function useTransfersNew(loginId: string) {
  const axios = useAxios();
  return useMutation<TransferDefinition, unknown, TransferNewCreate>(
    ['transfersList', loginId],
    async (payload) => {
      const { data, status } = await axios.post('/transfers/new', payload);
      if (status >= 200 && status < 300) {
        return data;
      }
      throw new Error(data?.message || 'Wrong requested data');
    },
  );
}
