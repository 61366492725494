import { ComponentProps } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  PaginationItem,
  TablePaginationProps,
  Typography,
} from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

export function CustomPagination(props: ComponentProps<typeof GridPagination>) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export function CustomNoRows({ message }: { message: string }) {
  return (
    <Typography p={2} textAlign={'center'} alignContent={'center'}>
      {message}
    </Typography>
  );
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="secondary"
      shape="rounded"
      size="small"
      className={className}
      count={pageCount}
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
          {...item}
          sx={{ height: '24px', width: '24px' }}
        />
      )}
      siblingCount={0}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as never, newPage - 1);
      }}
    />
  );
}
