import React, { useState } from 'react';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { usePammMoneyManagersAccounts } from '../../../api/accounts';
import { Currency } from '../../../api/general';
import { ModalLayout } from '../../../layouts/Modal/ModalLayout';

import { Confirmation } from './Stepper/Confirmation';
import { CreateAccount } from './Stepper/CreateAccount';
import { MoneyManager } from './Stepper/MoneyManager';

export interface PammAccountData {
  ownerId: number;
  name: string;
  description: string;
  leverage: number;
  isPublic: boolean;
  currency: Currency;
  activeStep: 0 | 1 | 2;
}

export interface PammInvestmentAccountData {
  ownerId: number;
  managerId: number;
  offerId: number;
  investment?: number;
  leverage: number;
  currency: Currency;
  activeStep: 0 | 1 | 2 | 3 | 4 | 5;
}

export function CreateMoneyManagerAccount() {
  const { data, isLoading } = usePammMoneyManagersAccounts();
  const { t } = useTranslation('createMoneyManagerAccount');
  const steps = [
    t('stepMoneyManager'),
    t('stepCreateAccount'),
    t('stepConfirmation'),
  ];
  const pammAccountData = useState<PammAccountData>({
    description: '',
    name: '',
    isPublic: false,
    ownerId: 0,
    leverage: 100,
    currency: 'USD',
    activeStep: 0,
  });

  function stepSwitch() {
    switch (pammAccountData[0].activeStep) {
      case 0:
        return (
          <MoneyManager
            moneyManagersAccounts={data || []}
            accountData={pammAccountData}
          ></MoneyManager>
        );
      case 1:
        return <CreateAccount accountData={pammAccountData}></CreateAccount>;
      case 2:
        return <Confirmation accountData={pammAccountData}></Confirmation>;
    }
  }

  return (
    <ModalLayout title={t('title')} isLoading={isLoading}>
      <Stepper
        activeStep={pammAccountData[0].activeStep}
        sx={{
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' },
        }}
      >
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} icon={null}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box>{stepSwitch()}</Box>
    </ModalLayout>
  );
}
