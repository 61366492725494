import { Reducer, useEffect, useMemo, useReducer } from 'react';

import { io } from 'socket.io-client';

export type MetaSymbol = {
  symbol: string;
  bid: string;
  ask: string;
  change: string;
  time: string;
};

export type State = { [symbol: string]: MetaSymbol };

type Action =
  | { type: 'partial'; payload: MetaSymbol }
  | { type: 'initial'; payload: State };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'initial':
      return action.payload;
    case 'partial':
      const prevTime = new Date(state?.[action.payload.symbol]?.time ?? 0);
      const time = new Date(action.payload.time);
      if (prevTime < time) {
        return {
          ...state,
          [action.payload.symbol]: { ...action.payload, time },
        };
      }

      return state;
  }
}

export function useSymbols() {
  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_CUSTOMER_API_URL ?? '', {
        protocols: ['websocket'],
      }),
    [],
  );

  const [state, dispatch] = useReducer(reducer as Reducer<State, Action>, {});

  useEffect(() => {
    const handleInitial = (payload: State) =>
      dispatch({ type: 'initial', payload });
    const handlePartial = (payload: MetaSymbol) =>
      dispatch({ type: 'partial', payload });
    socket.on('initial', handleInitial);
    socket.on('partial', handlePartial);

    return () => {
      socket.off('initial', handleInitial);
      socket.off('partial', handlePartial);
    };
  }, [socket]);

  return state;
}
