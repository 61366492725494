import React from 'react';

import { Box, CircularProgress } from '@mui/material';

export function CircularProgressBox() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
