import { ComponentProps } from 'react';

import { styled } from '@mui/material/styles';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell, { tableCellClasses } from '@mui/material/TableCell';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';

export const TableContainer = MuiTableContainer;
export const Table = MuiTable;
export const TableHead = MuiTableHead;
export const TableBody = MuiTableBody;

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  height: 'auto',
  padding: '0 20px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

export function DesktopTableCell({
  ...props
}: ComponentProps<typeof TableCell>) {
  return (
    <TableCell {...props} sx={{ display: { xs: 'none', md: 'table-cell' } }} />
  );
}

export const BodyTableRow = styled(MuiTableRow)(({ theme }) => ({
  height: '42px',
  border: 0,
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const HeadTableRow = styled(MuiTableRow)(() => ({
  height: '38px',
  fontWeight: 600,
}));
