import { useMemo } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';

import { i18n } from './i18n';
import { AuthProvider } from './providers/auth';
import { AxiosProvider } from './providers/axios';
import { router } from './router';
import { theme } from './theme';

function App() {
  const queryClient = useMemo(() => new QueryClient(), []);

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AxiosProvider
              baseURL={
                process.env.REACT_APP_CUSTOMER_API_URL ?? document.location.host
              }
            >
              <RouterProvider router={router} />
            </AxiosProvider>
          </AuthProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
