import React from 'react';

import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePammManagerOffers } from '../../../../api/accounts';
import { DataGridStyled } from '../../../../components/Grid/DataGrid';
import {
  CustomNoRows,
  CustomPagination,
} from '../../../../components/Grid/Pagination';
import { DashboardLayout } from '../../../../layouts/Dashboard/DashboardLayout';

export function Offers() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('offers');
  const { data, isLoading } = usePammManagerOffers(Number(id));
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('name'),
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'tradingInterval',
      headerName: t('tradingInterval'),
      type: 'string',
      minWidth: 200,
    },
    {
      field: 'minInitialInvestment',
      headerName: t('minimalInvestment'),
      minWidth: 50,
      type: 'number',
      flex: 1,
    },
    {
      field: 'action',
      headerName: t('action'),
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          window.location.href = `/pamm/manager/${params.row.managerId}/offer/${params.row.id}`;
        };

        return (
          <Button variant="contained" color="secondary" onClick={onClick}>
            {t('join')}
          </Button>
        );
      },
    },
  ];

  const rows = data
    ? data.map(({ id, name, settings, managerId }) => ({
        id,
        managerId,
        name,
        minInitialInvestment: settings?.minInitialInvestment,
        tradingInterval: `${settings?.tradingInterval?.count} ${settings?.tradingInterval?.type}`,
      }))
    : [];
  return (
    <DashboardLayout title={t('title')} isLoading={isLoading}>
      <DataGridStyled
        rowHeight={43}
        columnHeaderHeight={39}
        rows={rows}
        loading={isLoading}
        columns={columns}
        slots={{
          pagination: CustomPagination,
          noRowsOverlay: () =>
            CustomNoRows({
              message: t('offerNotExist'),
            }),
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableColumnSelector
        autoHeight
      />
    </DashboardLayout>
  );
}
