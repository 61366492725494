import React, { PropsWithChildren, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreatePammInvestments } from '../../../../../api/accounts';
import { PammInvestmentAccountData } from '../CreateInvestment';

export function Confirmation({
  accountData,
}: PropsWithChildren & {
  accountData: [
    PammInvestmentAccountData,
    React.Dispatch<React.SetStateAction<PammInvestmentAccountData>>,
  ];
}) {
  const navigate = useNavigate();
  const create = useCreatePammInvestments();
  const { t } = useTranslation('offerStepper');

  useEffect(() => {
    if (create.isSuccess) {
      navigate('/pamm');
    }
  }, [create.isSuccess]);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('offer')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>{accountData[0].name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('account')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {accountData[0].ownerId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('leverage')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              1:{accountData[0].leverage}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>{t('currency')}</TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {accountData[0].currency}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: 18 }}>
              {t('initialInvestment')}
            </TableCell>
            <TableCell sx={{ fontSize: 18 }}>
              {accountData[0].investment}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={accountData[0].activeStep === 0}
          onClick={() => {
            accountData[1]((prev) => ({
              ...prev,
              activeStep: prev.ownerId ? 0 : 1,
            }));
          }}
          sx={{ mr: 1 }}
        >
          {t('back')}
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <LoadingButton
          variant="contained"
          onClick={() => create.mutateAsync(accountData[0])}
          loading={create.isLoading || create.isSuccess}
        >
          {t('confirm')}
        </LoadingButton>
      </Box>
    </TableContainer>
  );
}
