import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

export function useQueryParam(name: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParam = searchParams.get(name);
  const setQueryParam = useCallback(
    (value: string) => {
      const nextSearchParams = new URLSearchParams(searchParams);
      if (value === null) {
        nextSearchParams.delete(name);
      } else {
        nextSearchParams.set(name, value);
      }
      setSearchParams(nextSearchParams);
    },
    [setSearchParams],
  );

  return [queryParam, setQueryParam] as const;
}
