import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAccountsList, useIbTree } from '../../../api/accounts';
import { useProfile } from '../../../api/auth';
import {
  BodyTableRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '../../../components/Table/Table';

import { CreateReferralLink } from './CreateReferralLink';
import { ReferralLink } from './ReferralLink';
import { Referrals } from './Referrals';

export function IBDashboard() {
  const { data } = useAccountsList();
  const { data: profile } = useProfile();
  const { data: ibTreeData = [] } = useIbTree();
  const { t } = useTranslation('ibDashboard');
  const ibAccounts = data
    ? data.filter(({ serverId }) => Number(serverId) === 1)
    : [];

  return (
    <Box>
      <Typography variant="h5">{t('ibSummary')}</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {ibAccounts.map((account) => (
              <BodyTableRow key={account.id}>
                <TableCell style={{ fontSize: '16px' }}>{account.id}</TableCell>
                <TableCell style={{ fontSize: '16px', textAlign: 'right' }}>
                  {Number(account.balance)?.toFixed(2)} {account.currency}
                </TableCell>
              </BodyTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {profile?.customFields?.custom_referral_invite_link?.length ? (
        <ReferralLink
          referralLink={`${profile.customFields.custom_referral_invite_link}`}
        />
      ) : (
        <CreateReferralLink />
      )}
      <br />
      {ibTreeData.length ? (
        <Referrals
          referrals={ibTreeData.sort(
            (a, b) => Number(a.referralIbId) - Number(b.referralIbId),
          )}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              height: '110px',
              width: '110px',
              borderRadius: '110px',
              backgroundColor: '#FFFFFF',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              m: 5,
            }}
          >
            <SettingsAccessibilityIcon sx={{ width: '57px', height: '57px' }} />
          </Box>
          <Typography variant={'h5'} textAlign={'center'}>
            {t('dontHaveReferrals')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
