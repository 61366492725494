import { useEffect, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Outlet, useNavigate } from 'react-router-dom';

import { useProfile } from '../../api/auth';
import { BaseLayout } from '../../layouts/Base/BaseLayout';
import { useAuth } from '../../providers/auth';

import { ProfileMenu } from './ProfileMenu';
import { Sidebar } from './Sidebar';

const drawerWidth = 240;
const headerHeight = 48;

const DashboardDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    background: theme.palette.primary.main,
    boxSizing: 'border-box',
    width: drawerWidth,
  },
}));

const DashboardToolbar = styled(Toolbar)(({ theme }) => ({
  background: theme.palette.common.white,
}));

export function Dashboard() {
  const auth = useAuth();
  const profile = useProfile();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (auth.authState === 'UNAUTHORIZED') {
      navigate('/auth/login');
    }
  }, [auth.authState, navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <BaseLayout
      isLoading={profile.isLoading}
      sx={{ display: 'flex', backgroundColor: '#F1F4F6' }}
    >
      <AppBar
        position="fixed"
        sx={{
          height: headerHeight,
          boxShadow: 'none',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <DashboardToolbar variant="dense">
          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            {/* <ToolbarTypography variant="h6" noWrap>
              Your profile has not yet been verified. Read more
            </ToolbarTypography> */}
          </Box>
          <ProfileMenu />
        </DashboardToolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <DashboardDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {<Sidebar />}
        </DashboardDrawer>
        <DashboardDrawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
          open
        >
          {<Sidebar />}
        </DashboardDrawer>
      </Box>
      <Box
        component="main"
        sx={{
          display: 'flex',
          pt: `${headerHeight}px`,
          width: { xs: '100vw', sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: '100vh',
        }}
      >
        <Outlet />
      </Box>
    </BaseLayout>
  );
}
