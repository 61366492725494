import { SyntheticEvent, useCallback } from 'react';

import { TabContext, TabList, useTabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { styled, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayout';

import { Information } from './Information';
import { Verification } from './Verification';

const FlexTab = styled(TabPanel)(({ theme, value }) => {
  const tab = useTabContext();

  return {
    display: tab?.value === value ? 'flex' : 'none',
    flex: tab?.value === value ? '1 1 auto' : 0,
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2, 0),
    },
  };
});

export function Profile() {
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation('profile');

  const handleChange = useCallback(
    (event: SyntheticEvent, nextTab: string) => {
      navigate(`/profile/${nextTab}`);
    },
    [navigate],
  );

  return (
    <DashboardLayout title={t('title')}>
      <TabContext value={tab ?? 'information'}>
        <TabList onChange={handleChange} centered>
          <Tab label={t('tabInformation')} value="information" />
          <Tab label={t('tabVerification')} value="verification" />
        </TabList>
        <FlexTab value="information" sx={{ alignItems: 'center' }}>
          <Information />
        </FlexTab>
        <FlexTab value="verification" sx={{ alignItems: 'center' }}>
          <Verification />
        </FlexTab>
      </TabContext>
    </DashboardLayout>
  );
}
