import { Account } from '../api/accounts';

export function filterAccounts(accounts: Account[], type: 'demo' | 'live') {
  return accounts.filter((acc) => {
    const serverId = Number(acc.serverId);
    return type !== 'demo'
      ? serverId === 2 || serverId === 5
      : serverId === 3 || serverId === 6;
  });
}
