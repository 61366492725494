import React, { useEffect, useMemo, useState } from 'react';

import { Button, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  TradingIntervalType,
  useCreateOffer,
  usePammManagerOffers,
  useUpdateOffer,
} from '../../../api/accounts';
import { ModalLayout } from '../../../layouts/Modal/ModalLayout';

interface OfferParams {
  name: string;
  description: string;
  isActive: boolean;
  tradingIntervalType: TradingIntervalType;
  tradingIntervalCount: number;
  minDeposit: number;
  minInitialInvestment: number;
  minWithdrawal: number;
}

export function OfferEdit() {
  const { id, offerId } = useParams<{ id: string; offerId: string }>();
  const { data, isLoading } = usePammManagerOffers(Number(id));
  const navigate = useNavigate();
  // TODO: refactor this 🤯
  // eslint-disable-next-line
  const upsertOffer = offerId ? useUpdateOffer(+offerId) : useCreateOffer();
  const isActive = useState<boolean>(true);
  const { t } = useTranslation('privateMM');

  const isOffer = useMemo(() => {
    if (data) {
      const offer = data.find(({ id }) => id === Number(offerId));
      isActive[1](offer?.isActive ?? isActive[0]);
      return offer;
    }
    return undefined;
  }, [data]);

  useEffect(() => {
    if (upsertOffer.isSuccess) {
      navigate(`/money_managers/manager/${id}`);
    }
  }, [upsertOffer.isSuccess]);

  const initialValues: OfferParams = {
    name: isOffer?.name || '',
    description: isOffer?.description || '',
    isActive: isOffer?.isActive || isActive[0],
    minDeposit: isOffer?.settings?.minDeposit || 0,
    minInitialInvestment: isOffer?.settings?.minInitialInvestment || 0,
    minWithdrawal: isOffer?.settings?.minWithdrawal || 0,
    tradingIntervalType:
      isOffer?.settings?.tradingInterval?.type ||
      TradingIntervalType.CalendarMonths,
    tradingIntervalCount: isOffer?.settings?.tradingInterval?.count || 1,
  };

  return (
    <ModalLayout
      title={`${offerId ? t('edit') : t('create')} ${t('offer')}`}
      isLoading={isLoading && upsertOffer.isLoading && upsertOffer.isSuccess}
    >
      {isOffer || !offerId ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) =>
            upsertOffer.mutateAsync({
              ...values,
              managerId: Number(id),
              isActive: isActive[0],
            })
          }
        >
          <Form>
            <Field
              component={CheckboxWithLabel}
              name="isActive"
              type="checkbox"
              Label={{
                label: (
                  <Typography variant="body1" component="span">
                    {t('active')}
                  </Typography>
                ),
              }}
              margin="normal"
              checked={isActive[0]}
              onChange={() => isActive[1]((prev) => !prev)}
            ></Field>
            <Field
              component={TextField}
              name="name"
              type="string"
              label={t('name')}
              margin="normal"
              fullWidth
            ></Field>
            <Field
              component={TextField}
              name="description"
              label={t('description')}
              margin="normal"
              fullWidth
            ></Field>
            <Field
              component={TextField}
              select
              name="tradingIntervalType"
              label={t('tradingIntervalType')}
              margin="normal"
              fullWidth
            >
              {Object.values(TradingIntervalType).map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={TextField}
              type="number"
              name="tradingIntervalCount"
              label={t('tradingInterval')}
              margin="normal"
              fullWidth
            ></Field>
            <Field
              component={TextField}
              type="number"
              name="minDeposit"
              label={t('minDeposit')}
              margin="normal"
              fullWidth
            ></Field>
            <Field
              component={TextField}
              type="number"
              name="minWithdrawal"
              label={t('minWithdrawal')}
              margin="normal"
              fullWidth
            ></Field>
            <Field
              component={TextField}
              type="number"
              name="minInitialInvestment"
              label={t('minInitialInvestment')}
              margin="normal"
              fullWidth
            ></Field>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button type="submit" variant="contained">
                {t('submit')}
              </Button>
            </Box>
          </Form>
        </Formik>
      ) : null}
    </ModalLayout>
  );
}
