import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import axios, { Axios } from 'axios';

import { useAuth } from './auth';

export const AxiosContext = createContext<Axios>(axios);

export function AxiosProvider({
  baseURL,
  children,
}: PropsWithChildren<{ baseURL: string }>) {
  const auth = useAuth();

  const client = useMemo(
    () =>
      axios.create({
        baseURL,
        headers: {
          Authorization: auth.token ? `Bearer ${auth.token}` : undefined,
        },
        validateStatus(status) {
          return status >= 200 && status < 500;
        },
      }),
    [auth.token],
  );

  useEffect(() => {
    const id = client.interceptors.response.use(
      (response) => {
        if (auth.token && response.status === 401) {
          auth.setToken(null);
          return Promise.reject(new Error('Unauthorized'));
        }

        return response;
      },
      (error) => {
        if (auth.token && error.response?.status === 401) {
          auth.setToken(null);
        }
      },
    );

    return () => client.interceptors.response.eject(id);
  }, [axios, auth.token]);

  return (
    <AxiosContext.Provider value={client}>{children}</AxiosContext.Provider>
  );
}

export function useAxios() {
  return useContext(AxiosContext);
}
