import React, { PropsWithChildren } from 'react';

import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

import { PammInvestmentAccountData } from '../CreateInvestment';

export function Investment({
  accountData,
}: PropsWithChildren & {
  accountData: [
    PammInvestmentAccountData,
    React.Dispatch<React.SetStateAction<PammInvestmentAccountData>>,
  ];
}) {
  const { t } = useTranslation('offerStepper');
  return (
    <Formik
      initialValues={accountData[0]}
      onSubmit={(values) =>
        accountData[1]((prev) => ({
          ...prev,
          ...values,
          activeStep: 4,
        }))
      }
    >
      <Form>
        <Field
          component={TextField}
          name="investment"
          label={t('investment')}
          margin="normal"
          fullWidth
        ></Field>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            disabled={accountData[0].activeStep === 0}
            onClick={() => {
              accountData[1]((prev) => ({
                ...prev,
                activeStep: prev.ownerId ? 1 : 2,
              }));
            }}
            sx={{ mr: 1 }}
          >
            {t('back')}
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button type="submit" variant="contained">
            {t('next')}
          </Button>
        </Box>
      </Form>
    </Formik>
  );
}
