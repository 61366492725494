import { ComponentProps, useMemo } from 'react';

import { Avatar } from '@mui/material';

export function NameAvatar({
  name,
  ...props
}: { name: string } & ComponentProps<typeof Avatar>) {
  const initials = useMemo(
    () =>
      name
        .split(' ')
        .map((word) => word[0])
        .join(''),
    [name],
  );

  return <Avatar {...props}>{initials}</Avatar>;
}
