import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

import { useCreatePromoIBLink } from '../../../api/accounts';

export function CreateReferralLink() {
  const createPromoIBLink = useCreatePromoIBLink();
  const { t } = useTranslation('ibDashboard');
  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={(values) => createPromoIBLink.mutateAsync(values)}
    >
      <Form>
        <Typography variant="h5" sx={{ pt: 4 }}>
          {t('createPromoLink')}
        </Typography>
        <Field
          component={TextField}
          name="name"
          label={t('name')}
          margin="normal"
          fullWidth
        ></Field>
        <LoadingButton
          loading={createPromoIBLink.isLoading || createPromoIBLink.isSuccess}
          type="submit"
          variant="contained"
          fullWidth
        >
          {t('createPromo')}
        </LoadingButton>
      </Form>
    </Formik>
  );
}
