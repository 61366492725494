import { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CreateAccountInput, useAccountCreate } from '../../api/accounts';
import { ModalLayout } from '../../layouts/Modal/ModalLayout';

const leverage = [1, 2, 5, 10, 33, 50, 100];

const initialValues: CreateAccountInput = {
  leverage: 100,
  currency: 'USD',
  type: 'live',
  platform: 'mt4',
};

export function OpenAccount() {
  const navigate = useNavigate();
  const { t } = useTranslation('openAccount');
  const create = useAccountCreate();

  useEffect(() => {
    if (create.isSuccess) {
      navigate('/accounts');
    }
  }, [create.isSuccess]);

  return (
    <ModalLayout title={t('title')}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => create.mutateAsync(values)}
      >
        <Form>
          <Field
            component={TextField}
            select
            name="platform"
            label={t('platform')}
            margin="normal"
            fullWidth
          >
            <MenuItem value="mt4">MetaTrader 4</MenuItem>
            <MenuItem value="mt5">MetaTrader 5</MenuItem>
          </Field>
          <Field
            component={TextField}
            select
            name="type"
            label={t('type')}
            margin="normal"
            fullWidth
          >
            <MenuItem value="live">Live</MenuItem>
            <MenuItem value="demo">Demo</MenuItem>
          </Field>
          <Field
            component={TextField}
            select
            name="leverage"
            label={t('leverage')}
            margin="normal"
            fullWidth
          >
            {leverage.map((value) => (
              <MenuItem key={value} value={value}>
                {`1:${value}`}
              </MenuItem>
            ))}
          </Field>
          <Field
            component={TextField}
            select
            name="currency"
            label={t('currency')}
            margin="normal"
            fullWidth
          >
            <MenuItem value="USD">USD</MenuItem>
          </Field>

          <LoadingButton
            loading={create.isLoading || create.isSuccess}
            type="submit"
            variant="contained"
            fullWidth
          >
            {t('createAccount')}
          </LoadingButton>
        </Form>
      </Formik>
    </ModalLayout>
  );
}
