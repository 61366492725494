import { forwardRef } from 'react';

import { LinkProps } from '@mui/material/Link';
import { PaletteColor, alpha, createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>(function Link(props, ref) {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: '#122D42',
    },
    secondary: {
      main: '#69E7F0',
      light: '#9DF9FF',
      dark: '#32E9F5',
    },
    action: {
      hover: 'rgba(241, 244, 246, 0.5)',
    },
  },
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          marginTop: 3,
        },
      },
    },
    MuiFilledInput: {
      defaultProps: { disableUnderline: true },
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          const color = theme.palette[ownerState.color ?? 'primary'];

          return {
            overflow: 'hidden',
            borderRadius: theme.shape.borderRadius,
            backgroundColor:
              theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
            border: '1px solid',
            borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
            transition: theme.transitions.create([
              'border-color',
              'background-color',
              'box-shadow',
            ]),
            '&:hover': {
              backgroundColor: '#fff',
            },
            '&.Mui-focused': {
              backgroundColor: '#fff',
              boxShadow: `${alpha(color.main, 0.25)} 0 0 0 2px`,
              borderColor: color.main,
            },
          };
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        contained: ({ theme, ownerState }) => {
          const color: PaletteColor =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            theme.palette[ownerState.color ?? 'primary'];
          return {
            boxShadow: 'none',
            color: color.contrastText,
            textTransform: 'none',
            background: `linear-gradient(180deg, ${color.light} 0%, ${color.dark} 100%)`,
            padding: theme.spacing(2, 4),
            transition: theme.transitions.create(['background', 'box-shadow']),
            '&:hover': {
              boxShadow: `${alpha(color.main, 0.25)} 0 0 0 2px`,
            },
          };
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        loadingIndicator: ({ theme, ownerState }) => {
          const color: PaletteColor =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            theme.palette[ownerState.color ?? 'primary'];
          return {
            color: color.contrastText,
          };
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // borderBottom: `2px solid ${alpha(primary, 0.3)}`,
          boxShadow: 'none',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textShadow: 'none',
          color: '#8896A0',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
});
