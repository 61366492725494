import { useState } from 'react';

import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import formatDate from 'date-fns/format';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  HeadTableRow,
  BodyTableRow,
} from '../../components/Table/Table';

import { symbolGroups } from './symbolGroups';
import { useSymbols } from './useSymbols';

export function SymbolsTable({
  lastCommited,
  lastVerified,
  hideTransparency,
}: {
  lastCommited?: string;
  lastVerified?: string;
  hideTransparency?: boolean;
}) {
  const symbols = useSymbols();
  const [symbolGroup, setSymbolGroup] =
    useState<keyof typeof symbolGroups>('forex');
  const { t } = useTranslation('symbolsTable');

  return (
    <Box>
      <Box sx={{ m: 4 }}>
        <Tabs
          value={symbolGroup}
          onChange={(e, value) => setSymbolGroup(value)}
          centered
        >
          {Object.keys(symbolGroups).map((groupKey) => (
            <Tab key={groupKey} label={t(`${groupKey}`)} value={groupKey} />
          ))}
        </Tabs>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <HeadTableRow>
              <TableCell>{t('symbol')}</TableCell>
              <TableCell>{t('bid')}</TableCell>
              <TableCell>{t('ask')}</TableCell>
              {!hideTransparency && (
                <TableCell>{t('lastCommitedAt')}</TableCell>
              )}
              {!hideTransparency && (
                <TableCell>{t('lastVerifiedAt')}</TableCell>
              )}
            </HeadTableRow>
          </TableHead>
          <TableBody>
            {symbolGroups[symbolGroup].reduce((rows, symbolKey) => {
              const symbol = symbols[symbolKey];
              if (symbol) {
                rows.push(
                  <BodyTableRow key={symbolKey}>
                    <TableCell component="th" scope="row">
                      {symbol.symbol}
                    </TableCell>
                    <TableCell>{symbol.bid}</TableCell>
                    <TableCell>{symbol.ask}</TableCell>
                    {!hideTransparency && (
                      <TableCell>
                        {lastCommited
                          ? formatDate(new Date(lastCommited), 'PPpp')
                          : 'never'}
                      </TableCell>
                    )}
                    {!hideTransparency && (
                      <TableCell>
                        {lastVerified
                          ? formatDate(new Date(lastVerified), 'PPpp')
                          : 'never'}
                      </TableCell>
                    )}
                  </BodyTableRow>,
                );
              } else {
                console.warn(`Symbol ${symbolKey} not found`);
              }

              return rows;
            }, [] as JSX.Element[])}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
