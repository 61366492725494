import { Navigate, useParams } from 'react-router-dom';

import { LocalStorageKeys } from '../../hooks/useLocalStorage';
import { useAuth } from '../../providers/auth';

import { RegistrationForm } from './RegistrationForm';

export function Registration() {
  const auth = useAuth();

  return auth.authState === 'UNAUTHORIZED' ? (
    <RegistrationForm />
  ) : (
    <Navigate to={'/'} />
  );
}

export function RegistrationByInvite() {
  const { inviterId = '' } = useParams<{ inviterId: string }>();
  localStorage.setItem(LocalStorageKeys.inviterId, inviterId);
  return <Navigate to={'/auth/registration'} />;
}
