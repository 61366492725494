import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

import { TransferDefinition, useTransfersList } from '../../api/transfers';
import { DataGridStyled } from '../../components/Grid/DataGrid';
import {
  CustomNoRows,
  CustomPagination,
} from '../../components/Grid/Pagination';

const columns: GridColDef[] = [
  {
    field: 'from',
    headerName: 'From',
    minWidth: 50,
    flex: 1,
  },
  {
    field: 'to',
    headerName: 'To',
    minWidth: 50,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    minWidth: 50,
    flex: 1,
  },
  {
    field: 'currency',
    headerName: 'currency',
    minWidth: 50,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 50,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 100,
    flex: 1,
  },
];

export function TransfersList() {
  const { fromLoginSid: fromLoginSidCur } = useParams<{
    fromLoginSid: string;
  }>();
  const fromLoginSid = fromLoginSidCur?.replace(/-\D+$/, '');
  const transfers = useTransfersList(String(fromLoginSid));
  const [rows, setRows] = useState<TransferDefinition[]>([]);
  const from = fromLoginSid?.replace(/.*-/, '');

  useEffect(() => {
    transfers.mutateAsync({}).then((data) => {
      setRows(
        data.map(({ fromLoginSid, toLoginSid, ...rest }) => {
          const from = fromLoginSid?.replace(/.*-/, '');
          const to = toLoginSid?.replace(/.*-/, '');
          return {
            ...rest,
            from,
            to,
          };
        }),
      );
    });
  }, [fromLoginSidCur]);

  return (
    <>
      <Button
        sx={{ my: 2 }}
        variant={'contained'}
        color="secondary"
        href={`/transfers/${fromLoginSidCur}/new`}
      >{`Create new transfer from ${from}`}</Button>
      <DataGridStyled
        rowHeight={43}
        columnHeaderHeight={39}
        rows={rows}
        loading={transfers.isLoading}
        columns={columns}
        slots={{
          pagination: CustomPagination,
          noRowsOverlay: () =>
            CustomNoRows({
              message: `You don't have any transfers yet`,
            }),
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        disableColumnSelector
        autoHeight
      />
    </>
  );
}
