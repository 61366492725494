import { forwardRef, useCallback } from 'react';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import {
  List,
  Toolbar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  listItemButtonClasses,
  alpha,
  Box,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  LinkProps as RouterLinkProps,
  useNavigate,
} from 'react-router-dom';

import { Logo } from '../../components/Logo/Logo';
import { MyAccounts, Transparency, Profile, Logout } from '../../icons';
import { useAuth } from '../../providers/auth';

const SidebarListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  stroke: theme.palette.primary.contrastText,
  opacity: 0.5,
  borderLeft: `5px solid transparent`,
  paddingLeft: 28,
  [`&:hover:not(.${listItemButtonClasses.selected})`]: {
    opacity: 1,
    color: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main,
    background: 'none',
  },
  [`&.${listItemButtonClasses.selected}`]: {
    opacity: 1,
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
  },
}));

const SideBarDivider = styled(Divider)(({ theme }) => ({
  borderColor: alpha(theme.palette.primary.contrastText, 0.15),
  margin: theme.spacing(6, 2, 2, 2),
}));

const SidebarCopyright = styled(ListItemText)(({ theme }) => ({
  color: alpha(theme.palette.primary.contrastText, 0.3),
  fontSize: 11,
  fontFamily: 'Exo, "Open Sans", sans-serif',
  textAlign: 'center',
}));

const RouterNavLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>(function RouterNavLink(props, ref) {
  const { href, className, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <NavLink
      ref={ref}
      to={href}
      className={({ isActive }) =>
        isActive ? `${className} ${listItemButtonClasses.selected}` : className
      }
      {...other}
    />
  );
});

function NavItemLink({
  title,
  path,
  icon,
}: {
  title: string;
  path: string;
  icon?: React.ReactNode;
}) {
  return (
    <ListItem disablePadding>
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <SidebarListItemButton href={path} component={RouterNavLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </SidebarListItemButton>
    </ListItem>
  );
}

function NavItemButton({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon?: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <ListItem disablePadding>
      <SidebarListItemButton onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </SidebarListItemButton>
    </ListItem>
  );
}

export function Sidebar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');

  const logout = useCallback(() => {
    auth.setToken(null);
    navigate('/auth/login');
  }, []);

  return (
    <>
      <Toolbar>
        <Logo />
      </Toolbar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: '1 1 auto',
        }}
      >
        <List
          sx={{
            marginTop: 2,
          }}
        >
          <NavItemLink
            path="/accounts"
            title={t('myAccounts')}
            icon={<MyAccounts />}
          />
          <NavItemLink
            path="/transparency"
            title={t('transparency')}
            icon={<Transparency />}
          />
          <NavItemLink
            path="/ib"
            title="IB Menu"
            icon={<SettingsAccessibilityIcon />}
          />
          <NavItemLink
            path="/pamm"
            title={t('pamm')}
            icon={<QueryStatsIcon />}
          />
          <NavItemLink
            path="/profile"
            title={t('profile')}
            icon={<Profile />}
          />
        </List>
        <List sx={{ marginTop: 2 }}>
          <NavItemButton
            title={t('logout')}
            onClick={logout}
            icon={<Logout />}
          />
          <SideBarDivider variant="middle" />
          <ListItem disablePadding>
            <SidebarCopyright
              disableTypography
              primary="© 2023 Exfor. All rights reserved."
            />
          </ListItem>
        </List>
      </Box>
    </>
  );
}
