import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Button, MenuItem, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccountsList } from '../../api/accounts';
import { Money } from '../../components/Money/Money';
import { MoreButton } from '../../components/MoreButton/MoreButton';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  DesktopTableCell,
  TableContainer,
  HeadTableRow,
  BodyTableRow,
} from '../../components/Table/Table';
import { DashboardLayout } from '../../layouts/Dashboard/DashboardLayout';

export function AccountsList() {
  const accountsList = useAccountsList();
  const navigate = useNavigate();
  const { t } = useTranslation('accountsList');

  return (
    <DashboardLayout
      title={t('myAccounts')}
      isLoading={accountsList.isLoading}
      actions={
        <>
          <Button
            href={process.env.REACT_APP_MT5_DOWNLOAD_LINK ?? ''}
            download
            variant="contained"
            color="secondary"
            startIcon={<GetAppIcon />}
            sx={{
              mr: { xs: 0, sm: 1 },
              marginY: { xs: 1, sm: 0 },
              minWidth: '172px',
              minHeight: '38px',
            }}
            LinkComponent="a"
          >
            {`${t('download')} MT5`}
          </Button>
          <Button
            href={process.env.REACT_APP_MT4_DOWNLOAD_LINK ?? ''}
            download
            variant="contained"
            color="secondary"
            startIcon={<GetAppIcon />}
            sx={{
              mr: { xs: 0, sm: 1 },
              marginY: { xs: 1, sm: 0 },
              minWidth: '172px',
              minHeight: '38px',
            }}
            LinkComponent="a"
          >
            {`${t('download')} MT4`}
          </Button>
          <Button
            href="new"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ minWidth: '172px', minHeight: '38px' }}
          >
            {t('openAccount')}
          </Button>
        </>
      }
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <HeadTableRow>
              <TableCell>{t('type')}</TableCell>
              <TableCell>{t('login')}</TableCell>
              <TableCell align="center">{t('leverage')}</TableCell>
              <TableCell align="center">{t('balance')}</TableCell>
              <DesktopTableCell align="center">{t('credit')}</DesktopTableCell>
              <DesktopTableCell align="center">{t('equity')}</DesktopTableCell>
              <DesktopTableCell align="center">{t('margin')}</DesktopTableCell>
              <TableCell align="center">{t('actions')}</TableCell>
            </HeadTableRow>
          </TableHead>
          <TableBody>
            {accountsList.data?.map((account) => (
              <BodyTableRow key={account.login}>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.login}</TableCell>
                <TableCell align="center">{`1:${account.leverage}`}</TableCell>
                <TableCell align="center">
                  <Money value={account.balance} currency={account.currency} />
                </TableCell>
                <DesktopTableCell align="center">
                  <Money value={account.credit} currency={account.currency} />
                </DesktopTableCell>
                <DesktopTableCell align="center">
                  <Money value={account.equity} currency={account.currency} />
                </DesktopTableCell>
                <DesktopTableCell align="center">
                  <Money value={account.margin} currency={account.currency} />
                </DesktopTableCell>
                <TableCell align="center">
                  <MoreButton key={account.login}>
                    <MenuItem
                      onClick={() => navigate(`/deposit?account=${account.id}`)}
                      disableRipple
                    >
                      {t('deposit')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(`/transfers/${account.id}/new`)}
                      disableRipple
                    >
                      {t('transfer')}
                    </MenuItem>
                  </MoreButton>
                </TableCell>
              </BodyTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardLayout>
  );
}
