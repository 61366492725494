import { PropsWithChildren } from 'react';

import { Box, CircularProgress } from '@mui/material';

export function LoadingLayout({
  isLoading,
  children,
}: PropsWithChildren<{
  isLoading: boolean;
}>) {
  return isLoading ? (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>{children}</>
  );
}
