import { useState } from 'react';

import { Button, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IbTreeDefinition } from '../../../api/accounts';
import {
  BodyTableRow,
  HeadTableRow,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '../../../components/Table/Table';

import { ReferralAccounts } from './ReferralAccounts';

export function Referrals({ referrals }: { referrals: IbTreeDefinition[] }) {
  const modal = useState(false);
  const { t } = useTranslation('referrals');
  const [referralId, setReferralId] = useState<number | null>(null);

  return (
    <>
      {referralId ? (
        <ReferralAccounts visible={modal} referralId={Number(referralId)} />
      ) : null}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <HeadTableRow>
              <TableCell>{t('referrals')}</TableCell>
              <TableCell align="right">{t('action')}</TableCell>
            </HeadTableRow>
          </TableHead>
          <TableBody>
            {referrals.map((referral: IbTreeDefinition) => (
              <BodyTableRow
                key={referral.referralIbId}
                onClick={() => {
                  setReferralId(Number(referral.referralIbId));
                  modal[1](true);
                }}
              >
                <TableCell style={{ fontSize: '12px' }}>
                  {referral.referralIbId}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setReferralId(Number(referral.referralIbId));
                      modal[1](true);
                    }}
                  >
                    {t('view')}
                  </Button>
                </TableCell>
              </BodyTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
