import { useEffect, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccountsList } from '../../api/accounts';
import { TransferNewCreate, useTransfersNew } from '../../api/transfers';
import { ModalLayout } from '../../layouts/Modal/ModalLayout';
import { filterAccounts } from '../../utils/accounts';

export function TransfersForm() {
  const navigate = useNavigate();
  const { fromLoginSid: fromLoginSidCur } = useParams<{
    fromLoginSid: string;
  }>();
  const fromLoginSid = fromLoginSidCur?.replace(/-\D+$/, '');
  const from = fromLoginSid?.replace(/.*-/, '');
  const accounts = useAccountsList();
  const create = useTransfersNew(String(fromLoginSidCur));
  const initialValues: TransferNewCreate = {
    toSidLogin: '',
    amount: 0,
    fromSidLogin: String(fromLoginSidCur),
  };

  const filteredAccounts = useMemo(
    () =>
      filterAccounts(accounts?.data || [], 'live')?.filter(
        ({ login }) => login !== from,
      ),
    [accounts.data],
  );

  useEffect(() => {
    if (create.isSuccess) {
      navigate(-1);
    }
  }, [create.isSuccess]);

  return accounts.data ? (
    <ModalLayout
      title={`Transfer from account ${from}`}
      isLoading={accounts.isLoading}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => create.mutateAsync(values)}
      >
        <Form>
          <Field
            component={TextField}
            select
            name="toSidLogin"
            label="To account"
            margin="normal"
            fullWidth
          >
            {filteredAccounts.map(({ login, id }) => (
              <MenuItem key={id} value={id}>
                {login}
              </MenuItem>
            ))}
            {filteredAccounts.length ? null : (
              <Box sx={{ px: 1 }}>There is no other accounts</Box>
            )}
          </Field>
          <Field
            component={TextField}
            name="amount"
            label="Amount"
            margin="normal"
            type="number"
            fullWidth
          ></Field>

          <LoadingButton
            loading={create.isLoading || create.isSuccess}
            type="submit"
            variant="contained"
            fullWidth
          >
            Create an account
          </LoadingButton>
        </Form>
      </Formik>
    </ModalLayout>
  ) : (
    <p>Loading ...</p>
  );
}
