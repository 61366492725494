import { PropsWithChildren, ReactNode } from 'react';

import { Box, Grid, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { CircularProgressBox } from '../../components/CircularProgress/CircularProgressBox';
import { PageHeader } from '../../components/Typography/PageHeader';

const Container = styled(Box)(() => ({
  backgroundColor: '#F1F4F6',
  flex: 1,
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export function DashboardLayout({
  title,
  isLoading,
  actions,
  outletDisabled,
  children,
}: PropsWithChildren<{
  title?: string;
  isLoading?: boolean;
  actions?: ReactNode;
  outletDisabled?: boolean;
}>) {
  return (
    <Container
      sx={{ padding: { xs: 1, sm: 4, backgroundColor: 'transparent' } }}
    >
      <Grid
        container
        sx={{ marginBottom: 2, flexDirection: { xs: 'column', sm: 'row' } }}
      >
        {title && !isLoading && (
          <Grid item>
            <PageHeader variant="h1">{title}</PageHeader>
          </Grid>
        )}

        <Grid item flexGrow={1} />
        {!isLoading && (
          <Grid
            item
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}
          >
            {actions}
          </Grid>
        )}
      </Grid>
      {isLoading ? <CircularProgressBox /> : children}
      {!outletDisabled && <Outlet />}
    </Container>
  );
}
