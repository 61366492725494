import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const DataGridStyled = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-main': {
    backgroundColor: '#ffffff',
  },
  '.MuiDataGrid-footerContainer': {
    backgroundColor: '#ffffff',
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#F1F4F680',
  },
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-row:hover': {
    backgroundColor: '#eeeeee',
  },
  '.MuiDataGrid-menuIconButton': {
    color: '#ffffff',
  },
  '.MuiDataGrid-sortIcon': {
    color: '#ffffff',
  },
}));
