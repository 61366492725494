import startOfDay from 'date-fns/startOfDay';
import { useQuery } from 'react-query';

import { useAxios } from '../providers/axios';

export type Ticker = {
  id: number;
  symbol: string;
  time: Date;
  brokerTime: Date;
  bid: number;
  ask: number;
  last: number;
  volume: number;
  side: string;
  hash: string;
  treeId: number;
};

export type Candle = {
  bucket: Date;
  symbol: string;
  open: number;
  close: number;
  high: number;
  low: number;
  volume: number;
};

export type Tree = {
  id: number;
  treeIpfsCid: string;
  otsIpfsCid: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  committedAt: string;
};

export function useTree(status: string) {
  const axios = useAxios();
  return useQuery(['tree', status], async () => {
    const response = await axios.get<Tree>('/history/tree/last', {
      params: { status },
    });

    return response.data;
  });
}

export function useCandles(symbol: string, timeframe: string) {
  const axios = useAxios();
  return useQuery(['candles', symbol, timeframe], async () => {
    const { data } = await axios.get<Candle[]>(
      `/history/candles/${symbol}/${timeframe}`,
      { params: { startDate: startOfDay(new Date()) } },
    );
    return data;
  });
}
