import React, { useMemo } from 'react';

import { Box, Typography, styled } from '@mui/material';

import { useProfile } from '../../api/auth';
import { NameAvatar } from '../../components/NameAvatar/NameAvatar';

const Avatar = styled(NameAvatar)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.action.hover,
  fontSize: 16,
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  margin: theme.spacing(1, 2),
}));

export function ProfileMenu() {
  const profile = useProfile();

  const name = useMemo(
    () =>
      [profile.data?.firstName, profile.data?.lastName]
        .filter(Boolean)
        .join(' '),
    [profile.data],
  );

  return (
    <Box sx={{ flexGrow: 0, display: 'inline-flex', alignItems: 'center' }}>
      <Avatar name={name} />
      <Name>
        {name} / {profile.data?.id}
      </Name>
    </Box>
  );
}
