import { useMutation } from 'react-query';

import { useAxios } from '../providers/axios';

export type Transaction = {
  id: string;
};

export type CreateDepositInput = {
  login: string;
  serverId: string;
  amount: number;
  currency: 'USD' | 'EUR';
};

export function useDepositCreate() {
  const axios = useAxios();

  return useMutation<
    { cashierKey: string; transactionId: string },
    unknown,
    CreateDepositInput
  >(['transactions', 'deposit'], async (payload) => {
    const { data, status } = await axios.post('/transactions/deposit', payload);
    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(data?.message || 'Deposit failed');
  });
}
